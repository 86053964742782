@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,500i,600|Rubik:300,400,500,700&amp;display=swap";
.default_bg {
	background-color: #0d1028
}

.hover_bg {
	background-color: #029115
}

.hover_bg_2 {
	background-color: #14bdee
}

.semi_dark_bg {
	background-color: #f7f7f7
}

.dark_2_bg {
	background-color: #002147
}

.semi_black_bg {
	background-color: rgba(0, 33, 71, .65)
}

.dark_3_bg {
	background-color: #f5f5f5
}

.black_bg {
	background-color: #0d1028
}

.black_2_bg {
	background-color: #151935
}

.bg_dark_9 {
	background-color: #f5f5f5
}

.height-800 {
	height: 800px
}

.height-600 {
	height: 600px
}

.height-400 {
	height: 400px
}

.height-300 {
	height: 240px
}

.height-700 {
	height: 700px
}

.section-ptb {
	padding-top: 70px;
	padding-bottom: 70px
}

@media(min-width:768px) and (max-width:991px) {
	.section-ptb {
		padding-top: 80px;
		padding-bottom: 80px
	}
}

@media(max-width:768px) {
	.section-ptb {
		padding-top: 80px;
		padding-bottom: 80px
	}
}

@media(max-width:575px) {
	.section-ptb {
		padding-top: 50px;
		padding-bottom: 50px
	}
}

.section-ptb-2 {
	padding-top: 225px;
	padding-bottom: 130px
}

@media(max-width:768px) {
	.section-ptb-2 {
		padding-top: 100px;
		padding-bottom: 70px
	}
}

@media(max-width:575px) {
	.section-ptb-2 {
		padding-top: 80px;
		padding-bottom: 50px
	}
}

.section-ptb-3 {
	padding-top: 130px;
	padding-bottom: 30px
}

@media(min-width:768px) and (max-width:991px) {
	.section-ptb-3 {
		padding-top: 80px;
		padding-bottom: 0
	}
}

@media(max-width:575px) {
	.section-ptb-3 {
		padding-top: 80px;
		padding-bottom: 0
	}
}

@media(max-width:768px) {
	.section-ptb-3 {
		padding-top: 100px;
		padding-bottom: 0
	}
}

.section-ptb-4 {
	padding-top: 130px;
	padding-bottom: 200px
}

@media(max-width:575px) {
	.section-ptb-4 {
		padding-top: 100px;
		padding-bottom: 90px
	}
}

.section-ptb-5 {
	padding-top: 130px;
	padding-bottom: 80px
}

@media(max-width:575px) {
	.section-ptb-5 {
		padding-top: 100px;
		padding-bottom: 60px
	}
}

.section-ptb-6 {
	padding-top: 130px;
	padding-bottom: 30px
}

@media(max-width:768px) {
	.section-ptb-6 {
		padding-top: 130px;
		padding-bottom: 0
	}
}

@media(max-width:575px) {
	.section-ptb-6 {
		padding-top: 130px;
		padding-bottom: 0
	}
}

.section-pb {
	padding-bottom: 100px
}

@media(min-width:768px) and (max-width:991px) {
	.section-pb {
		padding-bottom: 80px
	}
}

@media(max-width:768px) {
	.section-pb {
		padding-bottom: 80px
	}
}

@media(max-width:575px) {
	.section-pb {
		padding-bottom: 50px
	}
}

.section-pt {
	padding-top: 70px
}

.pt-60 {
	padding-top: 60px
}

.mt-30 {
	margin-top: 30px
}

@media(max-width:768px) {
	header .container {
		max-width: 96%
	}
}

@media(max-width:575px) {
	header .container {
		max-width: 96%
	}
}

.translate_30 {
	transform: translateY(-30%)
}

@media(max-width:768px) {
	.translate_30 {
		transform: translateY(0)
	}
}

@media(max-width:575px) {
	.translate_30 {
		transform: translateY(0)
	}
}

.translate_34 {
	transform: translateY(-34%)
}

@media(max-width:768px) {
	.translate_34 {
		transform: translateY(0)
	}
}

@media(max-width:575px) {
	.translate_34 {
		transform: translateY(0)
	}
}

.button_one {
	display: inline-block;
	position: relative;
	background-color: #029115;
	border: 0;
	color: #fff;
	padding: 15px 25px;
	transition: all .4s linear 0s;
	text-transform: uppercase;
	font-family: poppins, sans-serif;
	font-weight: 400;
	font-size: 14px
}

.button_one:hover {
	text-decoration: none;
	background-color: rgba(255, 82, 2, .85);
	color: #fff
}

.special-button {
	display: inline-block;
	position: relative;
	background-color: #029115;
	border: 0;
	color: #fff;
	padding: 16px 80px 16px 16px;
	transition: all .4s linear 0s;
	font-size: 15px;
	font-weight: 500;
	text-transform: uppercase
}

@media(max-width:768px) {
	.special-button {
		font-size: 12px
	}
	.special-button>i {
		display: none
	}
}

.special-button:hover {
	text-decoration: none;
	background-color: rgba(255, 82, 2, .85);
	color: #fff
}

.button_one_alt {
	display: inline-block;
	position: relative;
	background-color: #fff;
	border: 2px solid #029115;
	color: #029115;
	transition: all .4s linear 0s;
	font-size: 15px;
	font-weight: 500;
	text-transform: uppercase;
	width: 150px;
	height: 50px;
	line-height: 50px;
	text-align: center
}

.button_one_alt:hover {
	text-decoration: none;
	background-color: rgba(255, 82, 2, .85);
	color: #fff;
	border-color: rgba(255, 82, 2, .85)
}

.button_two {
	display: inline-block;
	position: relative;
	background-color: #14bdee;
	border: 0;
	color: #fff;
	padding: 10px 35px;
	line-height: 26px;
	height: 46px;
	transition: all .4s linear 0s;
	font-size: 15px;
	font-weight: 500;
	border-radius: 30px;
	text-transform: uppercase;
	box-shadow: 0 .5rem 1rem rgba(20, 189, 238, .35)
}

.button_two:hover {
	text-decoration: none;
	background-color: rgba(20, 189, 238, .7);
	color: #fff
}

.button_two_plane {
	display: inline-block;
	position: relative;
	background-color: #14bdee;
	border: 0;
	color: #fff;
	padding: 12px 25px;
	transition: all .4s linear 0s;
	font-size: 15px;
	font-weight: 500;
	text-transform: uppercase
}

.button_two_plane:hover {
	text-decoration: none;
	background-color: rgba(20, 189, 238, .7);
	color: #fff
}

.button_three {
	display: inline-block;
	position: relative;
	background-color: #91c73d;
	border: 0;
	color: #fff;
	padding: 10px 25px;
	transition: all .4s linear 0s;
	font-size: 15px;
	font-weight: 400;
	border-radius: 30px;
	text-transform: uppercase
}

@media(max-width:575px) {
	.button_three {
		padding: 10px
	}
}

.button_three:hover {
	text-decoration: none;
	background-color: #adadad;
	color: #fff
}

.button_four {
	display: inline-block;
	position: relative;
	background-color: #fff;
	border: 2px solid #029115;
	color: #029115;
	padding: 10px 25px;
	transition: all .4s linear 0s;
	font-size: 15px;
	font-weight: 400;
	text-transform: uppercase;
	cursor: pointer
}

.button_four.active {
	background-color: #029115;
	color: #fff
}

@media(max-width:575px) {
	.button_four {
		padding: 10px
	}
}

.button_four:hover {
	text-decoration: none;
	background-color: #029115;
	color: #fff
}

.button_five {
	display: inline-block;
	position: relative;
	background-color: #029115;
	color: #fff;
	padding: 10px 35px;
	transition: all .4s linear 0s;
	font-size: 15px;
	font-weight: 400;
	text-transform: uppercase;
	border: 2px solid #029115;
	cursor: pointer
}

.button_five.active {
	background-color: #fff;
	color: #029115
}

@media(max-width:575px) {
	.button_five {
		padding: 10px
	}
}

.button_five:hover {
	text-decoration: none;
	background-color: #fff;
	color: #029115
}

.button_block {
	display: block;
	width: 100%;
	height: 50px;
	border: 0;
	color: #fff;
	text-transform: uppercase;
	font-weight: 500;
	transition: all .4s linear 0s
}

.rounded-btn {
	display: inline-block;
	position: relative;
	background-color: #d0f2fc;
	border: 0;
	color: #14bdee;
	width: 35px;
	height: 35px;
	line-height: 35px;
	border-radius: 50%;
	transition: all .4s linear 0s;
	font-size: 15px;
	font-weight: 400;
	border-radius: 30px;
	text-transform: uppercase;
	font-size: 16px
}

.rounded-btn i {
	color: #14bdee
}

.rounded-btn:hover {
	text-decoration: none;
	background-color: #14bdee;
	color: #fff
}

.rounded-btn:hover i {
	color: #fff
}

.theme-list {
	display: inline-block
}

.theme-list ul {
	margin: 0;
	padding: 0;
	list-style: none
}

.theme-list ul li:last-child a {
	padding-bottom: 0
}

.theme-list ul li a {
	display: inline-block;
	position: relative;
	padding: 5px;
	color: #002147;
	transition: all .4s linear 0s
}

.theme-list ul li a:hover {
	text-decoration: none;
	color: #029115
}

.theme-list ul li a:before {
	content: "\f058";
	font-family: "font awesome 5 free";
	font-weight: 900;
	padding-right: 5px;
	color: #029115
}

.theme-list-2 {
	display: inline-block
}

.theme-list-2 ul {
	margin: 0;
	padding: 0;
	list-style: none
}

.theme-list-2 ul a {
	display: inline-block;
	position: relative;
	padding: 10px;
	color: #002147;
	transition: all .4s linear 0s
}

.theme-list-2 ul a:hover {
	text-decoration: none;
	color: #14bdee
}

.theme-list-2 ul a:before {
	content: "\f058";
	font-family: "font awesome 5 free";
	font-weight: 900;
	padding-right: 5px;
	color: #14bdee
}

.theme-list-3 {
	/* display: inline-block */
}

.theme-list-3 ul {
	margin: 0;
	padding: 0;
	list-style: none
}

.theme-list-3 ul li {
	position: relative;
	padding: 10px;
	color: #002147;
	transition: all .4s linear 0s;
	font-size: 16px
}

.theme-list-3 ul li:hover a {
	text-decoration: none;
	color: #91c73d
}

.theme-list-3 ul li:before {
	content: "\f058";
	font-family: "font awesome 5 free";
	font-weight: 900;
	padding-right: 5px;
	color: #91c73d
}

.theme-list-4 {
	display: inline-block
}

.theme-list-4 ul {
	margin: 0;
	padding: 0;
	list-style: none
}

.theme-list-4 ul li {
	display: block;
	position: relative;
	padding: 10px;
	color: #002147;
	transition: all .4s linear 0s;
	font-size: 16px
}

.theme-list-4 ul li:hover a {
	text-decoration: none;
	color: #029115
}

.theme-list-4 ul li:before {
	content: "\f00c";
	font-family: "font awesome 5 free";
	font-weight: 900;
	padding-right: 5px;
	color: #029115;
	font-size: 14px
}

.section-title {
	padding-bottom: 20px
}

.section-title h4 {
	color: #029115;
	text-transform: uppercase;
	font-size: 18px;
	font-weight: 500
}

.section-title h2 {
	color: #002147;
	font-size: 34px;
	font-family: poppins;
	font-weight: 600
}

@media(max-width:575px) {
	.section-title h2 {
		font-size: 35px
	}
}

.sub-title {
	font-family: poppins;
	font-weight: 500;
	font-size: 50px;
	color: #002147
}

@media(max-width:575px) {
	.sub-title {
		font-size: 35px
	}
}

.sm-sub-title {
	font-family: poppins;
	font-weight: 600;
	font-size: 30px;
	color: #002147
}

.section-title-2 {
	padding-bottom: 20px
}

.section-title-2 h4 {
	color: #14bdee;
	text-transform: uppercase;
	font-size: 18px;
	font-weight: 500
}

.section-title-2 h2 {
	color: #002147;
	font-size: 50px;
	font-family: poppins;
	font-weight: 600
}

@media(max-width:575px) {
	.section-title-2 h2 {
		font-size: 40px
	}
}

.section-title-3 {
	padding-bottom: 20px
}

.section-title-3 h4 {
	color: #91c73d;
	text-transform: uppercase;
	font-size: 18px;
	font-weight: 400
}

.section-title-3 h2 {
	color: #002147;
	font-size: 50px;
	font-family: poppins;
	font-weight: 600
}

@media(max-width:575px) {
	.section-title-3 h2 {
		font-size: 40px
	}
}

.section-title-6 {
	padding-bottom: 20px
}

.section-title-6 h4 {
	color: #fff;
	text-transform: uppercase;
	font-size: 18px;
	font-weight: 600
}

.section-title-6 h2 {
	color: #fff;
	font-size: 50px;
	font-family: poppins;
	font-weight: 600
}

@media(max-width:575px) {
	.section-title-6 h2 {
		font-size: 40px
	}
}
.semi_dark_bg span{
	text-align: right;
	display: block;
}
@media(max-width:575px) {
	.semi_dark_bg span{
		text-align: left;
	}
}
.single-section-title {
	margin-bottom: 50px
}

.single-section-title h2 {
	display: inline-block;
	font-size: 50px;
	color: #002147;
	position: relative;
	font-family: poppins;
	font-weight: 600
}

.single-section-title h2:after {
	content: "";
	position: absolute;
	left: 0;
	bottom: -15px;
	width: 100px;
	height: 3px;
	background-color: #14bdee
}

.single-section-title-2 {
	margin-bottom: 50px
}

.single-section-title-2 h2 {
	display: inline-block;
	font-size: 50px;
	color: #002147;
	position: relative;
	font-family: poppins;
	font-weight: 600
}

.single-section-title-2 h2:after {
	content: "";
	position: absolute;
	left: 0;
	bottom: -15px;
	width: 100px;
	height: 3px;
	background-color: #91c73d
}

.section-heading {
	position: relative;
	font-size: 35px;
	color: #002147;
	font-family: poppins;
	font-weight: 600
}

.section-heading:before {
	content: "";
	position: absolute;
	width: 100%;
	height: 5px;
	top: 50%;
	left: 40%;
	background-color: #029115
}

@media(max-width:768px) {
	.section-heading:before {
		left: 48%
	}
}

@media(min-width:768px) and (max-width:991px) {
	.section-heading:before {
		left: 40%
	}
}

@media(max-width:575px) {
	.section-heading:before {
		left: 50%;
		content: none
	}
}

.overlay {
	position: relative
}

.overlay:before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(34, 34, 34, .45)
}

.overlay_2 {
	position: relative
}

.overlay_2:before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 33, 71, .8)
}

.overlay_3 {
	position: relative
}

.overlay_3:before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, .8);
	padding: 25px;
	border-radius: 25px
}

@media(min-width:992px) {
	.overlay_3:before {
		display: none
	}
}

.border-2 {
	border: 2px solid #029115;
	transition: all .4s linear 0s
}

.border-3 {
	border: 2px solid #14bdee;
	transition: all .4s linear 0s
}

.border-4 {
	border: 2px solid #eff7e2;
	transition: all .4s linear 0s
}

.border-5 {
	border: 2px solid #d0f2fc;
	transition: all .4s linear 0s
}

.border-6 {
	border: 2px solid #ddd;
	transition: all .4s linear 0s
}

.border-7 {
	border: 5px solid #1d2144;
	transition: all .4s linear 0s
}

.border-8 {
	border: 5px solid #eff7e2;
	transition: all .4s linear 0s
}

.border-bottom-2 {
	border-bottom: 2px solid #029115
}

.button_icon i {
	font-size: 20px;
	padding-left: 5px
}

@media(min-width:768px) and (max-width:991px) {
	.mb-3d-5 {
		padding-bottom: 3rem
	}
}

@media(max-width:768px) {
	.mb-3d-5 {
		padding-bottom: 3rem
	}
}

@media(max-width:575px) {
	.mb-3d-5 {
		padding-bottom: 3rem
	}
}

@media(min-width:768px) and (max-width:991px) {
	.mt-3d-5 {
		padding-top: 3rem
	}
}

@media(max-width:768px) {
	.mt-3d-5 {
		padding-top: 3rem
	}
}

@media(max-width:575px) {
	.mt-3d-5 {
		padding-top: 3rem
	}
}

@media(max-width:768px) {
	.mb-xs-5 {
		padding-bottom: 3rem
	}
}

@media(max-width:575px) {
	.mb-xs-5 {
		padding-bottom: 3rem
	}
}

@media(max-width:575px) {
	.mb-mob-5 {
		padding-bottom: 3rem
	}
}

@media(max-width:768px) {
	.mb-xs-4 {
		padding-bottom: 2rem
	}
}

@media(max-width:575px) {
	.mb-xs-4 {
		padding-bottom: 2rem
	}
}

@media(max-width:768px) {
	.my-xs-5 {
		margin: 5rem 0
	}
}

@media(max-width:575px) {
	.my-xs-5 {
		margin: 5rem 0
	}
}

@media(max-width:768px) {
	.my-xs-4 {
		margin: 4rem 0
	}
}

@media(max-width:575px) {
	.my-xs-4 {
		margin: 4rem 0
	}
}

@media(min-width:768px) and (max-width:991px) {
	.mb-bd-5 {
		padding-bottom: 3rem
	}
}

@media(max-width:768px) {
	.mb-bd-5 {
		padding-bottom: 3rem
	}
}

@media(max-width:575px) {
	.mb-bd-5 {
		padding-bottom: 3rem
	}
}

@media(max-width:768px) {
	.mt-xs-5 {
		padding-top: 3rem
	}
}

@media(max-width:575px) {
	.mt-xs-5 {
		padding-top: 3rem
	}
}

.shadow-theme-lg {
	box-shadow: 0 1rem 3rem 1rem rgba(20, 189, 238, .175)!important
}

.height-470 {
	height: 470px
}

@media(min-width:992px) {
	.pr-120-lg {
		padding-right: 120px
	}
}

.translate_50 {
	transform: translateY(50%)!important
}

.widget-title {
	display: inline-block;
	position: relative;
	color: #002147;
	font-size: 24px;
	margin: 0;
	font-weight: 500
}

.widget-title:before {
	content: "";
	position: absolute;
	left: 0;
	bottom: -5px;
	width: 70px;
	height: 3px;
	background-color: #029115
}

.pagination-wrapper {
	margin-top: 60px
}

.pagination-wrapper ul {
	margin: 0;
	padding: 0;
	list-style: none
}

.pagination-wrapper ul li {
	display: inline-block
}

.pagination-wrapper ul li:last-child a {
	margin-right: 0
}

.pagination-wrapper ul li a {
	display: inline-block;
	margin-right: 25px;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background-color: #efefef;
	color: #666;
	text-align: center;
	line-height: 50px;
	transition: all .4s linear 0s
}

.pagination-wrapper ul li a:hover {
	background-color: #029115;
	color: #fff;
	text-decoration: none
}

.link_btn {
	display: inline-block;
	color: #029115;
	font-weight: 500;
	text-transform: uppercase;
	font-size: 15px;
	transition: all .4s linear 0s
}

.link_btn i {
	transition: all .4s linear 0s
}

.link_btn:hover {
	text-decoration: none;
	color: #029115
}

.link_btn:hover i {
	padding-left: 5px
}

.tags-list p {
	display: inline-block;
	margin-bottom: 0;
	color: #002147;
	font-size: 16px;
	font-weight: 500;
	padding-right: 5px
}

@media(max-width:575px) {
	.tags-list p {
		font-size: 14px
	}
}

.tags-list ul {
	margin: 0;
	padding: 0;
	display: inline-block
}

.tags-list ul li {
	display: inline-block;
	margin-right: 5px
}

.tags-list ul li a {
	position: relative;
	color: #666;
	font-size: 16px;
	transition: all .4s linear 0s
}

.tags-list ul li a:hover {
	text-decoration: none;
	color: #029115
}

.tags-list ul li a:after {
	content: " , ";
	font-size: 20px
}

.tags-list ul li:last-child a:after {
	content: none
}

.share-option p {
	display: inline-block;
	margin-bottom: 0;
	color: #002147;
	font-weight: 500;
	padding-right: 5px
}

@media(max-width:575px) {
	.share-option p {
		padding-right: 0;
		padding-left: 5px;
		font-size: 14px
	}
}

.share-option ul {
	margin: 0;
	padding: 0;
	list-style: none;
	display: inline-block
}

.share-option ul li {
	display: inline-block
}

.share-option ul li:last-child a {
	margin-right: 0
}

.share-option ul li a {
	display: inline-block;
	width: 40px;
	height: 40px;
	line-height: 40px;
	margin-right: 10px;
	text-align: center;
	background-color: #fff;
	border: 2px solid #029115;
	border-radius: 50%;
	color: #029115;
	transition: all .4s linear 0s
}

@media(max-width:575px) {
	.share-option ul li a {
		width: 35px;
		height: 35px;
		line-height: 35px;
		margin-right: 0;
		border-width: 1px;
		font-size: 12px
	}
}

.share-option ul li a:hover {
	background-color: #029115;
	color: #fff
}

@media(min-width:768px) and (max-width:991px) {
	.mb-xs-2 {
		margin-bottom: 2rem
	}
}

@media(max-width:768px) {
	.mb-xs-2 {
		margin-bottom: 2rem
	}
}

@media(max-width:575px) {
	.mb-xs-2 {
		margin-bottom: 2rem
	}
}

@media(min-width:768px) and (max-width:991px) {
	.mb-xs-3 {
		margin-bottom: 3rem
	}
}

@media(max-width:768px) {
	.mb-xs-3 {
		margin-bottom: 3rem
	}
}

@media(max-width:575px) {
	.mb-xs-3 {
		margin-bottom: 3rem
	}
}

@media(max-width:575px) {
	.mb-mob-3 {
		margin-bottom: 3rem
	}
}

body {
	font-family: rubik, sans-serif;
	color: #484848;
    font-size: 15px;
    font-weight: 400;
}

body p {
	line-height: 24px;
	text-align: justify;
}

.header-top {
	display: none;
	min-height: 45px
}

@media(min-width:992px) {
	.header-top {
		display: block
	}
}

.header-top-content {
	display: flex;
	justify-content: space-between
}

.header-top-content .contact-info ul {
	margin: 0;
	padding: 0;
	list-style: none
}

.header-top-content .contact-info ul li {
	display: inline-block;
	line-height: 45px;
	padding-right: 40px;
	color: #fff;
	font-size: 14px
}

.header-top-content .contact-info ul li i {
	color: #14bdee;
	padding-right: 5px
}

.header-top-content .contact-info ul li:last-child {
	padding-right: 0
}

.single_info {
	display: inline-flex;
	padding-left: 30px;
	justify-content: center;
	align-items: center;
	justify-content: center;
	align-items: end
}

@media(max-width:575px) {
	.single_info {
		padding-left: 10px
	}
}

.single_info .info_data {
	display: inline-block
}

.single_info .info_data h6 {
	font-weight: 500;
	color: #002147
}

.single_info .info_data p {
	margin-bottom: 0;
	color: #666
}

@media(max-width:768px) {
	.single_info .info_data p {
		font-size: 14px
	}
}

@media(max-width:575px) {
	.single_info .info_data p {
		font-size: 14px
	}
}

.single_info img {
	padding-right: 20px
}

@media(max-width:575px) {
	.single_info img {
		padding-right: 10px
	}
}

.single_info .button_icon {
	position: absolute;
	right: 0;
	top: 50%;
	width: 60px;
	height: 100%;
	background-color: rgba(34, 34, 34, .09);
	text-align: center;
	transform: translateY(-50%);
	line-height: 100%;
	display: flex;
	align-items: center;
	justify-content: center
}

.navbar-toggler:focus {
	outline-color: #029115
}
.bg_dark_mobile {
	background-color: #002147
}
@media(max-width:768px) {
	.main-menu-area.bg_dark_mobile {
		background-color: #0d1028
	}
}

@media(max-width:575px) {
	.main-menu-area.bg_dark_mobile {
		background-color: #002147
	}
}

@media(min-width:768px) and (max-width:991px) {
	.header_top_area .site_logo {
		display: none
	}
}

@media(max-width:768px) {
	.header_top_area .site_logo {
		display: none
	}
}

.header_top_area .site_info .single_info:first-child {
	padding-left: 0
}

@media(min-width:768px) and (max-width:991px) {
	.header_top_area .site_info {
		width: 100%
	}
}

@media(max-width:768px) {
	.header_top_area .site_info {
		width: 100%
	}
}

@media(max-width:575px) {
	.header_top_area .button_icon {
		display: none
	}
}

@media(max-width:575px) {
	.header_top_area {
		display: none
	}
}
.navbar-collapse{
	justify-content: center;
}
.main-menu ul li a {
	color: #fff;
	padding: 1rem 2rem!important;
	transition: all .4s linear 0s;
}

.main-menu ul li a:hover {
	background-color: #029115
}

@media(min-width:992px) {
	.main-menu ul li a {
		padding: 0.7rem 0.7rem!important;
    	font-size: 1rem;
	}
	.dropdown .dropdown-item{
		font-size: 14px;
		padding: 0.5rem 0.9rem !important;
		border-bottom: 1px solid #09274a;
	}
}

.main-menu .dropdown-menu {
	background-color: #0d1028
}

.main-menu .navbar {
	padding: 0
}

.main-menu .navbar-brand i {
	color: #fff
}

.main-menu .dropdown-menu {
	transition: all .4s linear 0s;
	margin: 0;
	padding: 0;
	border-radius: 0;
	border: 0
}

.main-menu .dropdown-menu .dropdown-item:hover {
	color: #fff
}

.main-menu .dropdown-toggle::after {
	content: "\f107";
	font-family: "font awesome 5 free";
	font-weight: 900;
	border-top: 0;
	vertical-align: baseline
}

.main-menu .nav-item.dropdown.show {
	background-color: #029115;
	color: #fff
}

@media(min-width:992px) {
	.main-menu .dropdown {
		transition: all .4s linear 0s
	}
	.main-menu .dropdown:hover {
		background-color: #029115
	}
	.main-menu .dropdown:hover>.dropdown-menu {
		display: block
	}
}

.main-menu .collapse-bar {
	position: relative
}

.main-menu .option-menu {
	position: absolute;
	right: 0;
	z-index: 5;
	margin-top: 8px
}

.main-menu .option-menu ul {
	margin: 0;
	padding: 0;
	list-style: none;
	background-color: #0d1028
}

.main-menu .navbar-brand {
	padding-right: .2rem
}

.main-menu .navbar i {
	color: #fff
}

@media(min-width:768px) and (max-width:991px) {
	.main-menu {
		padding: 15px 20px
	}
	.main-menu .navbar-brand {
		display: none
	}
}

@media(max-width:768px) {
	.main-menu {
		padding: 15px 0
	}
	.main-menu .navbar-brand {
		display: none
	}
}

.main-menu-2 ul li:last-child a {
	padding-right: 0!important
}

.main-menu-2 ul li a {
	color: #666;
	padding: 1rem!important;
	transition: all .4s linear 0s;
	font-weight: 500
}

.main-menu-2 ul li a:hover {
	color: #14bdee
}

.main-menu-2 .navbar {
	padding: 0
}

.main-menu-2 .navbar-brand i {
	color: #fff
}

.main-menu-2 .dropdown-menu {
	transition: all .4s linear 0s;
	margin: 0;
	padding: 0;
	border-radius: 0
}

.main-menu-2 .dropdown-toggle::after {
	content: "\f107";
	font-family: "font awesome 5 free";
	font-weight: 900;
	border-top: 0;
	vertical-align: baseline
}

.main-menu-2 .nav-item.dropdown.show {
	background-color: #002147
}

.main-menu-2 .nav-item.dropdown.show a {
	color: #fff
}

.main-menu-2 .nav-item.dropdown.show .dropdown-item {
	background-color: #002147
}

@media(min-width:992px) {
	.main-menu-2 .dropdown {
		transition: all .4s linear 0s
	}
	.main-menu-2 .dropdown:hover>.dropdown-menu {
		display: block
	}
}

.main-menu-2 .navbar i {
	color: #666
}

.main-menu-3 ul li a {
	color: #989898;
	padding: 1rem!important;
	transition: all .4s linear 0s;
	font-weight: 500
}

.main-menu-3 ul li a:hover {
	color: #fff;
	background-color: #91c73d
}

.main-menu-3 ul li a.active {
	background-color: #91c73d;
	color: #fff
}

@media(min-width:1199px) {
	.main-menu-3 ul li a {
		padding: 1rem 1.5rem!important
	}
}

.main-menu-3 .navbar {
	padding: 0
}

.main-menu-3 .navbar-brand i {
	color: #fff
}

.main-menu-3 .dropdown-menu {
	transition: all .4s linear 0s;
	margin: 0;
	padding: 0;
	border-radius: 0;
	border: 0
}

.main-menu-3 .dropdown-toggle::after {
	content: "\f107";
	font-family: "font awesome 5 free";
	font-weight: 900;
	border-top: 0;
	vertical-align: baseline
}

.main-menu-3 .nav-item.dropdown.show {
	background-color: #14bdee;
	color: #666
}

@media(min-width:992px) {
	.main-menu-3 .dropdown {
		transition: all .4s linear 0s
	}
	.main-menu-3 .dropdown:hover>.dropdown-menu {
		display: block
	}
}

.main-menu-3 .navbar i {
	color: #666
}

@media(min-width:768px) and (max-width:991px) {
	.mobile_site_logo {
		display: inline-block!important
	}
}

@media(max-width:768px) {
	.mobile_site_logo {
		display: inline-block!important;
		background: #fff;
	    padding: 5px;
	    border-radius: 5px;
	}
}

@media(min-width:992px) {
	.dropdown-menu .dropdown .dropdown-menu {
		left: 100%;
		top: 0%;
		margin: 0 20px;
		border-width: 0
	}
	.dropdown-menu .dropdown .dropdown-menu {
		margin: 0;
		border-width: 1px
	}
}

.slider-section {
	position: relative;
	overflow: hidden
}

.swiper-container-horizontal>.swiper-pagination-bullets {
	bottom: 10px;
	width: auto;
	top: 50%;
	left: 90%;
	transform: translateY(-50%)
}

.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
	margin: 20px 0;
	display: block;
	position: relative;
	width: 20px;
	height: 20px;
	background-color: #fff;
	opacity: 1
}

.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet:before {
	content: "";
	position: absolute;
	left: -5px;
	top: -5px;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	border: 2px solid #fff
}

.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
	background-color: #029115
}

.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active:before {
	content: "";
	position: absolute;
	left: -5px;
	top: -5px;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	border: 2px solid #029115
}

.slider-area {
	position: relative;
	display: flex;
	align-items: center;
	background-size: cover;
	background-position: center center;
	width: 100%
}

.slider-area-2 {
	position: relative;
	display: flex;
	align-items: center;
	background-size: cover;
	background-position: right top;
	width: 100%
}

.slider-area-2 .slider-content {
	position: relative;
	z-index: 9
}

.slider-area-2 .slider-content h6 {
	position: relative;
	color: #14bdee;
	text-transform: uppercase;
	font-size: 20px
}

.slider-area-2 .slider-content h2 {
	position: relative;
	color: #002147;
	font-weight: 700
}

.slider-area-2 .slider-content h2 span {
	display: block;
	font-weight: 400
}

.slider-area-2 .slider-content-2 {
	position: relative;
	z-index: 9
}

.slider-area-2 .slider-content-2 h6 {
	position: relative;
	color: #14bdee;
	text-transform: uppercase;
	font-size: 20px
}

.slider-area-2 .slider-content-2 h2 {
	position: relative;
	color: #fff;
	font-family: poppins, sans-serif;
	font-weight: 600;
	font-size: 26px;
	text-transform:capitalize;
	margin-bottom: 15px;
}

.slider-area-2 .slider-content-2 h2 span {
	display: block;
	font-weight: 400
}

.slider-area-2 .slider-content-2 p {
	position: relative;
	color: #fff;
	font-size: 16px;
	line-height: 24px;
}

@media(min-width:768px) and (max-width:991px) {
	.slider-area-2 .slider-content-2 .display-3 {
		font-size: 1.8rem;
		line-height: normal;
	}
	.button_two{
		height: 36px;
		line-height: 26px;
		padding: 5px 15px;
		font-size: 12px;
	}
}

@media(max-width:768px) {
	.slider-area-2 .slider-content-2 .display-3 {
		font-size: 1.6rem;
		line-height: normal;
	}
	.button_two{
		height: 36px;
		line-height: 26px;
		padding: 5px 15px;
		font-size: 12px;
	}
	.height-300{
		height: auto;
	}
}

@media(max-width:575px) {
	.slider-area-2 .slider-content-2 .display-3 {
		font-size: 1.4rem;
	}
}

.slider-area-3 {
	position: relative;
	display: flex;
	align-items: center;
	background-size: cover;
	background-position: right top;
	width: 100%
}

.slider-area-3:before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(34, 34, 34, .6);
	z-index: 9
}

.slider-area-3 .slider-content {
	position: relative
}

.slider-area-3 .slider-content h6 {
	color: #fff;
	text-transform: uppercase;
	font-size: 20px;
	font-weight: 400
}

.slider-area-3 .slider-content h2 {
	color: #fff
}

.slider-area-3 .slider-content h2 span {
	display: block;
	font-weight: 400
}

.slider-area-3 .slider-content-2 {
	position: relative
}

.slider-area-3 .slider-content-2 h6 {
	color: #14bdee;
	text-transform: uppercase;
	font-size: 20px
}

.slider-area-3 .slider-content-2 h2 {
	color: #002147
}

.slider-area-3 .slider-content-2 h2 span {
	display: block;
	font-weight: 400
}

.slider-area-3 .slider-content-2 p {
	font-family: poppins, sans-serif
}

@media(min-width:768px) and (max-width:991px) {
	.slider-area-3 .slider-content-2 .display-3 {
		font-size: 3rem
	}
}

@media(max-width:768px) {
	.slider-area-3 .slider-content-2 .display-3 {
		font-size: 3rem
	}
}

@media(max-width:575px) {
	.slider-area-3 .slider-content-2 .display-3 {
		font-size: 2rem
	}
}

.swiper-button-next {
	right: 30px;
	background-color: #14bdee;
	color: #fff;
	padding: 25px;
	border-radius: 50%;
	background-size: 20px 30px;
	transition: all .4s linear 0s;
	background-image: none
}

.swiper-button-next:before {
	content: "\f105";
	font-family: "font awesome 5 free";
	font-weight: 900;
	position: absolute;
	left: 0;
	right: 0;
	text-align: center;
	color: #fff;
	font-size: 25px;
	line-height: 25px;
	top: 50%;
	transform: translateY(-50%)
}

.swiper-button-next:focus {
	outline: 0;
	box-shadow: none
}

@media(min-width:768px) and (max-width:991px) {
	.swiper-button-next {
		display: none
	}
}

@media(max-width:768px) {
	.swiper-button-next {
		display: none
	}
}

@media(max-width:575px) {
	.swiper-button-next {
		display: none
	}
}

.swiper-button-next:hover {
	background-color: #adadad
}

.swiper-button-prev {
	right: 30px;
	background-color: #14bdee;
	color: #fff;
	padding: 25px;
	border-radius: 50%;
	background-size: 20px 30px;
	transition: all .4s linear 0s;
	background-image: none
}

.swiper-button-prev:before {
	content: "\f104";
	font-family: "font awesome 5 free";
	font-weight: 900;
	position: absolute;
	left: 0;
	right: 0;
	text-align: center;
	color: #fff;
	font-size: 25px;
	line-height: 25px;
	top: 50%;
	transform: translateY(-50%)
}

.swiper-button-prev:focus {
	outline: 0;
	box-shadow: none
}

@media(min-width:768px) and (max-width:991px) {
	.swiper-button-prev {
		display: none
	}
}

@media(max-width:768px) {
	.swiper-button-prev {
		display: none
	}
}

@media(max-width:575px) {
	.swiper-button-prev {
		display: none
	}
}

.swiper-button-prev:hover {
	background-color: #adadad
}

.kindergarten .swiper-button-prev {
	background: #d7d8da;
	padding: 24px
}

.kindergarten .swiper-button-prev:hover {
	background: #15bdee
}

.kindergarten .swiper-button-next {
	background: #d7d8da;
	padding: 24px
}

.kindergarten .swiper-button-next:hover {
	background: #15bdee
}

@media(max-width:575px) {
	.swiper-pagination {
		display: none!important
	}
}

.slider-content {
	position: relative;
	z-index: 10
}

@media(max-width:575px) {
	.slider-content {
		padding: 0!important;
		margin: 0!important
	}
}

.slider-content p {
	margin-bottom: 0;
	font-size: 20px
}

.slider-content h2 {
	font-weight: 500;
	font-family: poppins
}

@media(min-width:768px) and (max-width:991px) {
	.slider-content .display-3 {
		font-size: 3rem
	}
}

@media(max-width:768px) {
	.slider-content .display-3 {
		font-size: 3rem
	}
}

@media(max-width:575px) {
	.slider-content .display-3 {
		font-size: 2rem
	}
}

@media(max-width:575px) {
	.slider-area.height-700 {
		height: 450px
	}
}

.services-section {
	position: relative;
	z-index: 5
}

.single-service {
	transition: all .4s linear 0s
}

.single-service .service-icon {
	margin-bottom: 2rem
}

.single-service a {
	color: #fff;
	font-weight: 500;
	transition: all .4s linear 0s
}

.single-service a i {
	transition: all .4s linear 0s
}

.single-service a:hover {
	text-decoration: none
}

.single-service a:hover i {
	padding-left: 5px
}

.single-service:hover {
	background-color: #029115
}

.service-text h4 {
	margin-bottom: 1.2rem;
	font-weight: 600
}

.single-service-2 h4 {
	color: #002147;
	font-weight: 600
}

.single-service-2 .service-icon {
	margin-bottom: 25px
}

.single-service-2 a {
	color: #14bdee;
	transition: all .4s linear 0s
}

.single-service-2 a i {
	transition: all .4s linear 0s
}

.single-service-2 a:hover {
	text-decoration: none
}

.single-service-2 a:hover i {
	padding-left: 5px
}

.single-service-3 {
	transition: all .4s linear 0s
}

.single-service-3.active {
	background-color: #029115
}

.single-service-3.active h4 {
	color: #fff
}

.single-service-3.active a {
	color: #fff;
	text-transform: uppercase;
	font-weight: 500
}

.single-service-3.active:hover {
	background-color: #029115;
	color: #fff
}

.single-service-3.active:hover a {
	color: #fff
}

.single-service-3.active:hover h4 {
	color: #fff
}

.single-service-3.active:hover .service-icon {
	padding: 15px;
	display: inline-block;
	background-color: #029115
}

.single-service-3 .service-icon {
	padding: 15px
}

.single-service-3 h4 {
	color: #002147
}

.single-service-3 .service-icon {
	margin-bottom: 25px
}

.single-service-3 a {
	color: #029115;
	transition: all .4s linear 0s
}

.single-service-3 a i {
	transition: all .4s linear 0s
}

.single-service-3 a:hover {
	text-decoration: none
}

.single-service-3 a:hover i {
	padding-left: 5px
}

.single-service-3:hover {
	background-color: #029115;
	color: #fff
}

.single-service-3:hover a {
	color: #fff
}

.single-service-3:hover h4 {
	color: #fff
}

.single-service-3:hover .service-icon {
	display: inline-block;
	background-color: #fff;
	border-radius: 50%
}

.single-service-4 {
	transition: all .4s linear 0s;
	height: 390px;
}

.single-service-4.active {
	background-color: #029115
}

.single-service-4.active h4 {
	color: #fff
}

.single-service-4.active a {
	color: #fff
}

.single-service-4 h4 {
	color: #002147;
	/*min-height: 55px;*/
	font-size: 20px;
}

.single-service-4 .service-icon {
	margin-bottom: 25px
}

.single-service-4 a {
	color: #029115;
	transition: all .4s linear 0s
}

.single-service-4 a i {
	transition: all .4s linear 0s
}

.single-service-4:hover {
	box-shadow: 0 1rem 3rem rgba(20, 189, 238, .35)!important;
	border-color: #14bdee
}

.featured-section-2 {
	background-size: 100% 53%;
	background-repeat: no-repeat
}

@media(max-width:768px) {
	.featured-section-2 {
		padding-bottom: 250px
	}
}

.feature-image {
	position: relative
}

.feature-image img {
	width: 100%
}

.single-featured {
	transition: all .4s linear 0s;
	box-shadow: none
}

.single-featured:hover {
	border-color: #029115!important
}

.single-featured .product-price {
	position: absolute;
	right: 30px;
	bottom: 30px
}

.single-featured-2 {
	transition: all .4s linear 0s;
	box-shadow: none
}

.single-featured-2:hover {
	box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
	border-color: #14bdee!important
}

.single-featured-2:hover .featured-text-and-info {
	border-color: #14bdee!important
}

.single-featured-2 .feature-image {
	position: relative
}

.single-featured-2 .feature-image img {
	width: 100%
}

.single-featured-2 .product-price-2 {
	position: absolute;
	right: 30px;
	bottom: 30px
}

.single-featured-2 .event-date-2 {
	position: absolute;
	right: 30px;
	bottom: 30px
}

.single-featured-3 {
	transition: all .4s linear 0s
}

.single-featured-3:hover .border-6 {
	border-color: #14bdee
}

.single-featured-3 h3 {
	padding-top: 30px;
	font-weight: 600
}

.single-featured-3 .featured-text-and-info {
	border-top-color: #14bdee;
	transition: all .4s linear .09s
}

.single-featured-4 {
	transition: all .4s linear 0s
}

.single-featured-4:hover .border-6 {
	border-color: #14bdee
}

.single-featured-4 h3 {
	padding-top: 30px;
	font-weight: 600
}

.single-featured-4 .featured-text-and-info {
	border-top-color: #14bdee;
	transition: all .4s linear 0s
}

.single-featured-4 .feature-image {
	position: relative
}

.single-featured-4 .feature-image .product-price-4 {
	position: absolute;
	right: 30px;
	bottom: 30px
}

@media(max-width:768px) {
	.single-featured-4 .feature-image img {
		width: 100%
	}
}

.single-featured-4:hover {
	box-shadow: 0 1rem 3rem rgba(145, 199, 61, .3)
}

.single-featured-5 {
	transition: all .4s linear 0s
}

.single-featured-5:hover .border-6 {
	border-color: #029115
}

.single-featured-5 h3 {
	padding-top: 30px;
	font-weight: 600
}

.single-featured-5 .featured-text-and-info {
	border-top-color: #029115;
	transition: all .4s linear .09s
}

.single-featured-5 .feature-image {
	position: relative
}

.single-featured-5 .feature-image .product-price-4 {
	position: absolute;
	right: 30px;
	bottom: 30px
}

.featured-text-and-info {
	position: relative
}

.feature-image {
	position: relative
}

.feature-status {
	display: flex;
	justify-content: space-between
}

.feature-status span {
	color: #a2a2a2
}

.feature-status i {
	color: #029115;
	font-size: 14px
}

.feature-status-2 {
	display: flex;
	justify-content: space-between
}

.feature-status-2 span {
	color: #a2a2a2
}

.feature-status-2 i {
	color: #14bdee;
	font-size: 14px
}

.feature-status-3 {
	display: flex;
	justify-content: space-between
}

.feature-status-3 span {
	color: #a2a2a2
}

.feature-status-3 span i {
	color: #f99e1c
}

.feature-status-3 i {
	color: #a2a2a2;
	font-size: 14px
}

.feature-status-4 {
	display: flex;
	justify-content: space-between
}

.feature-status-4 span {
	color: #a2a2a2
}

.feature-status-4 span i {
	color: #91c73d
}

.feature-status-4 i {
	color: #91c73d;
	font-size: 14px
}

.feature-content h3 {
	font-family: poppins, sans-serif;
	font-size: 24px;
	font-weight: 600;
	margin-bottom: 0;
	padding-bottom: 15px
}

.feature-content h3 a {
	color: #002147;
	transition: all .4s linear 0s
}

.feature-content h3 a:hover {
	text-decoration: none;
	color: #029115
}

.feature-content-2 h3 {
	font-family: poppins, sans-serif;
	font-size: 24px;
	font-weight: 600;
	margin-bottom: 0;
	padding-bottom: 15px
}

.feature-content-2 h3 a {
	color: #002147;
	transition: all .4s linear 0s
}

.feature-content-2 h3 a:hover {
	text-decoration: none;
	color: #14bdee
}

.feature-content-3 h3 {
	font-family: poppins, sans-serif;
	font-size: 24px;
	font-weight: 600;
	margin: 0;
	padding-top: 0;
	padding-bottom: 15px
}

.feature-content-3 h3 a {
	color: #002147;
	transition: all .4s linear 0s
}

.feature-content-3 h3 a:hover {
	text-decoration: none;
	color: #91c73d
}

.product-price {
	display: inline-block;
	padding: .6rem 1rem;
	background-color: #029115;
	color: #fff;
	font-size: 22px;
	font-weight: 500;
	text-align: center
}

.product-price p {
	margin-bottom: 0
}

.product-price-2 {
	display: inline-block;
	padding: .6rem 1rem;
	background-color: #14bdee;
	color: #fff;
	font-size: 22px;
	font-weight: 500;
	text-align: center
}

.product-price-2 p {
	margin-bottom: 0
}

.product-price-3 {
	display: inline-block;
	width: 70px;
	height: 70px;
	border-radius: 50%;
	border: 2px solid #14bdee;
	font-size: 24px;
	font-weight: 500;
	line-height: 70px;
	color: #14bdee;
	background-color: #fff;
	z-index: 2;
	position: absolute;
	bottom: -35px;
	left: 50%;
	transform: translateX(-50%)
}

.product-price-3 p {
	margin-bottom: 0;
	line-height: 68px
}

.product-price-4 {
	display: inline-block;
	padding: .6rem 1rem;
	background-color: #91c73d;
	color: #fff;
	font-size: 22px;
	font-weight: 500;
	text-align: center
}

.product-price-4 p {
	margin-bottom: 0
}

.product-price-5 {
	display: inline-block;
	width: 70px;
	height: 70px;
	border-radius: 50%;
	border: 2px solid #029115;
	font-size: 24px;
	font-weight: 500;
	line-height: 70px;
	color: #029115;
	background-color: #fff;
	z-index: 2;
	position: absolute;
	bottom: -35px;
	left: 50%;
	transform: translateX(-50%)
}

.product-price-5 p {
	margin-bottom: 0;
	line-height: 68px
}

.event-date-2 {
	display: inline-block;
	padding: .6rem 1rem;
	background-color: #14bdee;
	color: #fff;
	font-size: 20px;
	text-align: center
}

.event-date-2 p {
	margin-bottom: 0
}

.courses-list {
	text-align: center
}

@media(max-width:768px) {
	.courses-list {
		text-align: left
	}
}

@media(max-width:575px) {
	.courses-list {
		text-align: left
	}
}

.courses-list ul li {
	cursor: pointer;
	margin: 5px
}

@media(max-width:768px) {
	.countdown-wrapper.translate_50 {
		transform: translateY(15%)!important
	}
}

@media(max-width:575px) {
	.countdown-wrapper.translate_50 {
		transform: translateY(5%)!important
	}
}

.single-countdown {
	text-align: center;
	border-width: 6px;
	border-style: solid;
	border-image: url("../images/border.png") 5;
	border-image-outset: inherit;
	border-image-repeat: no-repeat;
	border-image-repeat: repeat
}

.single-countdown p {
	color: #fff;
	font-weight: 600
}

.single-countdown span {
	color: #029115;
	font-size: 60px;
	font-weight: 600
}

@media(min-width:768px) and (max-width:991px) {
	.single-countdown {
		border-width: 5px
	}
}

.single-countdown-2 {
	text-align: center;
	border-width: 7px;
	border-style: solid;
	border-image: url("../images/border.png") 7;
	border-image-outset: inherit;
	border-image-repeat: no-repeat;
	border-image-repeat: repeat
}

.single-countdown-2 p {
	color: #666;
	font-weight: 600;
	font-size: 14px
}

.single-countdown-2 span {
	color: #14bdee;
	font-size: 60px;
	font-weight: 600
}

@media(min-width:768px) and (max-width:991px) {
	.single-countdown-2 {
		border-width: 6px
	}
}

.single-countdown-3 {
	text-align: center;
	border-width: 6px;
	border-style: solid;
	border-image: url("../images/border.png") 5;
	border-image-outset: inherit;
	border-image-repeat: no-repeat;
	border-image-repeat: repeat;
	background-color: #002147
}

.single-countdown-3 p {
	color: #fff;
	font-weight: 600
}

.single-countdown-3 span {
	color: #14bdee;
	font-size: 60px;
	font-weight: 600
}

@media(min-width:768px) and (max-width:991px) {
	.single-countdown-3 {
		border-width: 5px
	}
}

.single-countdown-4 {
	text-align: center;
	border-width: 6px;
	border-style: solid;
	border-image: url("../images/border.png") 5;
	border-image-outset: inherit;
	border-image-repeat: no-repeat;
	border-image-repeat: repeat;
	background-color: #0d1028
}

.single-countdown-4 p {
	color: #fff;
	font-weight: 600;
	font-size: 20px
}

.single-countdown-4 span {
	color: #91c73d;
	font-size: 60px;
	font-weight: 600
}

@media(min-width:768px) and (max-width:991px) {
	.single-countdown-4 p {
		font-size: 15px
	}
}

.single-countdown-5 {
	text-align: center;
	background-color: #002147;
	border-width: 6px;
	border-style: solid;
	border-color: #fff
}

.single-countdown-5 p {
	color: #fff;
	font-weight: 600;
	font-size: 20px
}

.single-countdown-5 span {
	color: #14bdee;
	font-size: 60px;
	font-weight: 600
}

@media(min-width:768px) and (max-width:991px) {
	.single-countdown-5 p {
		font-size: 15px
	}
}

.half_column_image {
	position: relative;
	transition: all .4s linear 0s
}

.half_column_image .video-play-btn {
	position: absolute;
	top: 50%;
	left: 50%;
	text-align: center;
	transform: translate(-50%, -50%)
}

.half_column_image .video-play-btn i {
	font-size: 55px;
	color: #029115;
	background-color: #fff;
	border-radius: 50%;
	padding: 10px;
	transition: all .4s linear 0s
}

.half_column_image:hover i {
	background-color: #029115;
	color: #fff
}

@media(min-width:768px) and (max-width:991px) {
	.half_column_image img {
		width: 100%
	}
}

@media(min-width:992px) {
	.half_column_image img {
		object-fit: cover
	}
}

.half_column_image .play-button-2 {
	display: inline-block;
	position: absolute;
	right: 0;
	bottom: 0;
	padding: 15px 25px;
	color: #fff;
	background-color: #91c73d;
	transition: all .4s linear 0s;
	font-weight: 500
}

.half_column_image .play-button-2:hover {
	text-decoration: none;
	background-color: rgba(145, 199, 61, .8)
}

.half_column_image_2 img {
	object-fit: cover
}

.half_column_content_2 h2 {
	font-size: 45px;
	color: #002147;
	padding-bottom: 15px;
	font-weight: 600;
	text-transform: capitalize
}

@media(max-width:768px) {
	.half_column_content_2 h2 {
		font-size: 30px
	}
}

@media(max-width:575px) {
	.half_column_content_2 h2 {
		font-size: 30px
	}
}

.why-ready{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
}
.why-ready .item{
	width: 30.333%;
    margin: 0 1.5% 20px;
    border: 2px dotted #1f4a82;
    padding: 7px 15px;
    color: #fff;
    border-radius: 10px;
    font-size: 18px;
    text-align: center;
    font-weight: 400;
    align-items: center;
    display: flex;

}


.post-meta-info {
	display: inline-block;
	border: 2px solid #029115;
	width: 50%;
	padding: 1.5rem;
	background-color: #fff9f6;
	margin-top: 5px
}

.post-meta-info ul {
	margin: 0;
	padding: 0;
	list-style: none
}

.post-meta-info ul li {
	color: #029115;
	padding: 5px
}

@media(max-width:768px) {
	.two-column-section.section-pt {
		padding: 50px 0
	}
}

@media(max-width:575px) {
	.two-column-section.section-pt {
		padding: 50px 0
	}
}

.our-team-carousel-4 {
	margin-bottom: -20px
}

.member-info {
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #fff;
	padding: 20px;
	font-family: poppins, sans-serif;
	transition: all .4s linear 0s
}

.member-info p {
	margin-bottom: 0;
	font-size: 14px
}

.member-info a {
	color: #fff;
	font-size: 14px
}

.member-info a:hover {
	text-decoration: none
}

.member-info h6 {
	font-size: 16px;
	font-weight: 500
}

.member-info span {
	font-size: 14px
}

.member-info-2 .extra-skilled p {
	margin-bottom: 0;
	padding: 5px 0
}

.member-info-2 .extra-skilled p strong {
	color: #002147;
	font-size: 14px
}

.member-info-2 span {
	color: #666;
	font-size: 14px
}

.member-info-2 h5 {
	font-size: 18px;
	color: #002147
}

.member-info-3 h5 {
	color: #002147;
	font-size: 20px;
	font-family: poppins, sans-serif;
	font-weight: 600
}

.member-info-3 span {
	color: #666;
	font-size: 14px;
	font-weight: 500
}

.member-info-4 h5 {
	color: #002147;
	font-size: 20px;
	font-family: poppins, sans-serif;
	font-weight: 600
}

.member-info-4 span {
	color: #666;
	font-size: 14px
}

.social-profile ul {
	margin: 0;
	padding: 0;
	list-style: none
}

.social-profile ul li a {
	display: inline-block;
	width: 35px;
	height: 35px;
	line-height: 35px;
	color: #029115;
	border: 1px solid #029115;
	border-radius: 50%;
	background-color: #fff;
	transition: all .4s linear 0s;
	text-align: center;
	margin: 5px 0
}

.social-profile ul li a:hover {
	background-color: #029115;
	color: #fff
}

.member-image {
	position: relative;
	overflow: hidden
}

.member-image .social-profile {
	position: absolute;
	top: 30px;
	right: 30px;
	opacity: 0;
	visibility: hidden;
	transition: all .4s linear 0s
}

.member-image img {
	width: 100%
}

.member-image-2 {
	position: relative;
	overflow: hidden
}

.member-image-3 {
	padding-bottom: 20px;
	overflow: hidden
}

.member-image-3 img {
	width: 200px!important;
	height: 200px;
	margin: 0 auto;
	border-radius: 50%
}

.social-profile-2 {
	position: absolute;
	bottom: 15px;
	text-align: center;
	opacity: 0;
	visibility: hidden;
	transition: all .4s linear 0s;
	left: 0;
	right: 0
}

.social-profile-2 ul {
	margin: 0;
	padding: 0;
	list-style: none
}

.social-profile-2 ul li {
	display: inline-block;
	margin: 0 5px
}

.social-profile-2 ul li a {
	display: inline-block;
	width: 40px;
	height: 40px;
	line-height: 40px;
	color: #14bdee;
	border-radius: 50%;
	background-color: #fff;
	transition: all .4s linear 0s;
	text-align: center;
	margin: 5px 0
}

.social-profile-2 ul li a:hover {
	background-color: #14bdee;
	color: #fff
}

.social-profile-3 {
	position: absolute;
	bottom: 15px;
	text-align: center;
	opacity: 0;
	visibility: hidden;
	transition: all .4s linear 0s;
	left: 0;
	right: 0
}

.social-profile-3 ul {
	margin: 0;
	padding: 0;
	list-style: none
}

.social-profile-3 ul li {
	display: inline-block;
	margin: 0 5px
}

.social-profile-3 ul li a {
	display: inline-block;
	width: 40px;
	height: 40px;
	line-height: 40px;
	color: #a1a1a1;
	border-radius: 50%;
	background-color: #efefef;
	transition: all .4s linear 0s;
	text-align: center;
	margin: 5px 0
}

.social-profile-3 ul li a:hover {
	background-color: #029115;
	color: #fff
}

.social-profile-4 {
	position: absolute;
	bottom: 15px;
	text-align: center;
	opacity: 0;
	visibility: hidden;
	transition: all .4s linear 0s;
	left: 0;
	right: 0
}

.social-profile-4 ul {
	margin: 0;
	padding: 0;
	list-style: none
}

.social-profile-4 ul li {
	display: inline-block;
	margin: 0 5px
}

.social-profile-4 ul li a {
	display: inline-block;
	width: 40px;
	height: 40px;
	line-height: 40px;
	color: #a1a1a1;
	border-radius: 50%;
	background-color: #efefef;
	transition: all .4s linear 0s;
	text-align: center;
	margin: 5px 0
}

.social-profile-4 ul li a:hover {
	background-color: #14bdee;
	color: #fff
}

.social-profile-5 {
	position: relative;
	text-align: center;
	transition: all .4s linear 0s
}

.social-profile-5 ul {
	margin: 0;
	padding: 0;
	list-style: none
}

.social-profile-5 ul li {
	display: inline-block;
	margin: 0 5px
}

.social-profile-5 ul li a {
	display: inline-block;
	width: 40px;
	height: 40px;
	line-height: 40px;
	color: #a1a1a1;
	border-radius: 50%;
	background-color: #efefef;
	transition: all .4s linear 0s;
	text-align: center;
	margin: 5px 0
}

.social-profile-5 ul li a:hover {
	background-color: #91c73d;
	color: #fff
}

.social-profile-6 {
	position: absolute;
	bottom: 0;
	text-align: center;
	transition: all .4s linear 0s;
	left: 0;
	right: 0;
	background-color: #002147;
	padding: 10px 0
}

.social-profile-6 ul {
	margin: 0;
	padding: 0;
	list-style: none
}

.social-profile-6 ul li {
	display: inline-block;
	margin: 0 5px
}

.social-profile-6 ul li a {
	display: inline-block;
	width: 40px;
	height: 40px;
	line-height: 40px;
	color: #fff;
	border-radius: 50%;
	background-color: #032d5c;
	transition: all .4s linear 0s;
	text-align: center;
	margin: 5px 0
}

.social-profile-6 ul li a:hover {
	background-color: #029115;
	color: #fff
}

.single-team {
	overflow: hidden
}

.single-team:hover .social-profile {
	opacity: 1;
	visibility: visible
}

.single-team:hover .member-info {
	background-color: #029115
}

.single-team:hover .social-profile-2 {
	opacity: 1;
	visibility: visible
}

.single-team:hover .social-profile-3 {
	opacity: 1;
	visibility: visible
}

.single-team:hover .social-profile-4 {
	opacity: 1;
	visibility: visible
}

.single-team:hover .member-info-4 {
	background-color: #029115;
	color: #fff;
	transition: all .4s linear 0s
}

.single-team:hover .member-info-4 .designation h5 {
	color: #fff
}

.single-team:hover .member-info-4 .designation span {
	color: #fff
}

.single-team:hover .member-image-2 {
	overflow: hidden
}

.single-team:hover .member-image-2 img {
	transform: scale3d(1.2, 1.2, 1.3)
}

.single-team img {
	width: 100%;
	transition: all .4s linear 0s
}

.border-opt .single-team {
	transition: all .4s linear 0s
}

.border-opt .single-team:hover .border {
	border-color: #14bdee!important;
	border-width: 2px!important
}

.show_navigation {
	position: relative
}

.show_navigation .owl-nav {
	position: absolute;
	top: -120px;
	right: 0
}

@media(min-width:768px) and (max-width:991px) {
	.show_navigation .owl-nav {
		display: none
	}
}

@media(max-width:768px) {
	.show_navigation .owl-nav {
		display: none
	}
}

.show_navigation .owl-nav button {
	margin: 10px
}

.show_navigation .owl-nav button i {
	font-size: 22px
}

.show_navigation .owl-nav button.owl-prev {
	width: 50px;
	height: 50px;
	background: #efefef;
	line-height: 55px;
	display: inline-block;
	border-radius: 50%;
	color: #a1a1a1;
	border: 1px solid #a1a1a1;
	transition: all .4s linear 0s
}

.show_navigation .owl-nav button.owl-prev:hover {
	background-color: #029115;
	border-color: #029115;
	color: #fff
}

.show_navigation .owl-nav button.owl-next {
	width: 50px;
	height: 50px;
	line-height: 55px;
	background: #efefef;
	display: inline-block;
	border-radius: 50%;
	color: #a1a1a1;
	border: 1px solid #a1a1a1;
	transition: all .4s linear 0s
}

.show_navigation .owl-nav button.owl-next:hover {
	background-color: #029115;
	border-color: #029115;
	color: #fff
}

.show_navigation .owl-nav button:focus {
	outline: 0;
	box-shadow: none
}

.show_navigation_2 {
	position: relative
}

.show_navigation_2 .owl-nav {
	position: absolute;
	top: -120px;
	right: 0
}

@media(min-width:768px) and (max-width:991px) {
	.show_navigation_2 .owl-nav {
		display: none
	}
}

@media(max-width:768px) {
	.show_navigation_2 .owl-nav {
		display: none
	}
}

.show_navigation_2 .owl-nav button {
	margin: 10px
}

.show_navigation_2 .owl-nav button i {
	font-size: 22px
}

.show_navigation_2 .owl-nav button.owl-prev {
	width: 50px;
	height: 50px;
	background: #efefef;
	line-height: 55px;
	display: inline-block;
	border-radius: 50%;
	color: #a1a1a1;
	border: 1px solid #a1a1a1;
	transition: all .4s linear 0s
}

.show_navigation_2 .owl-nav button.owl-prev:hover {
	background-color: #14bdee;
	border-color: #14bdee;
	color: #fff
}

.show_navigation_2 .owl-nav button.owl-next {
	width: 50px;
	height: 50px;
	line-height: 55px;
	background: #efefef;
	display: inline-block;
	border-radius: 50%;
	color: #a1a1a1;
	border: 1px solid #a1a1a1;
	transition: all .4s linear 0s
}

.show_navigation_2 .owl-nav button.owl-next:hover {
	background-color: #14bdee;
	border-color: #14bdee;
	color: #fff
}

.show_navigation_2 .owl-nav button:focus {
	outline: 0;
	box-shadow: none
}

.show_navigation_3 {
	position: relative
}

.show_navigation_3 .owl-nav {
	font-size: 30px
}

@media(min-width:768px) and (max-width:991px) {
	.show_navigation_3 .owl-nav {
		display: none
	}
}

@media(max-width:768px) {
	.show_navigation_3 .owl-nav {
		display: none
	}
}

.show_navigation_3 .owl-nav button {
	margin: 10px
}

.show_navigation_3 .owl-nav button i {
	font-size: 22px
}

.show_navigation_3 .owl-nav button.owl-prev {
	position: absolute;
	width: 50px;
	height: 50px;
	background: #efefef;
	line-height: 50px;
	display: inline-block;
	border-radius: 50%;
	color: #a1a1a1;
	border: 1px solid #a1a1a1;
	transition: all .4s linear 0s;
	top: 50%;
	transform: translateY(-50%);
	display: none
}

@media(min-width:1199px) {
	.show_navigation_3 .owl-nav button.owl-prev {
		display: inline-block;
		left: -80px
	}
}

.show_navigation_3 .owl-nav button.owl-prev:hover {
	background-color: #029115;
	border-color: #029115;
	color: #fff
}

.show_navigation_3 .owl-nav button.owl-next {
	position: absolute;
	width: 50px;
	height: 50px;
	line-height: 50px;
	background: #efefef;
	display: inline-block;
	border-radius: 50%;
	color: #a1a1a1;
	border: 1px solid #a1a1a1;
	transition: all .4s linear 0s;
	transform: translateY(-50%);
	top: 50%;
	display: none
}

@media(min-width:1199px) {
	.show_navigation_3 .owl-nav button.owl-next {
		display: inline-block;
		right: -80px
	}
}

.show_navigation_3 .owl-nav button.owl-next:hover {
	background-color: #029115;
	border-color: #029115;
	color: #fff
}

.show_navigation_3 .owl-nav button:focus {
	outline: 0;
	box-shadow: none
}

.show_navigation_4 {
	position: relative
}

.show_navigation_4 .owl-nav {
	font-size: 30px
}

@media(min-width:768px) and (max-width:991px) {
	.show_navigation_4 .owl-nav {
		display: none
	}
}

@media(max-width:768px) {
	.show_navigation_4 .owl-nav {
		display: none
	}
}

.show_navigation_4 .owl-nav button {
	margin: 10px
}

.show_navigation_4 .owl-nav button i {
	font-size: 22px
}

.show_navigation_4 .owl-nav button.owl-prev {
	position: absolute;
	width: 50px;
	height: 50px;
	background: #efefef;
	line-height: 50px;
	display: inline-block;
	border-radius: 50%;
	color: #a1a1a1;
	border: 1px solid #a1a1a1;
	transition: all .4s linear 0s;
	left: -90px;
	top: 50%;
	transform: translateY(-50%)
}

.show_navigation_4 .owl-nav button.owl-prev:hover {
	background-color: #91c73d;
	border-color: #91c73d;
	color: #fff
}

@media(min-width:992px) {
	.show_navigation_4 .owl-nav button.owl-prev {
		display: none
	}
}

@media(min-width:1199px) {
	.show_navigation_4 .owl-nav button.owl-prev {
		display: block
	}
}

.show_navigation_4 .owl-nav button.owl-next {
	position: absolute;
	width: 50px;
	height: 50px;
	line-height: 50px;
	background: #efefef;
	display: inline-block;
	border-radius: 50%;
	color: #a1a1a1;
	border: 1px solid #a1a1a1;
	transition: all .4s linear 0s;
	right: -90px;
	transform: translateY(-50%);
	top: 50%
}

.show_navigation_4 .owl-nav button.owl-next:hover {
	background-color: #91c73d;
	border-color: #91c73d;
	color: #fff
}

@media(min-width:992px) {
	.show_navigation_4 .owl-nav button.owl-next {
		display: none
	}
}

@media(min-width:1199px) {
	.show_navigation_4 .owl-nav button.owl-next {
		display: block
	}
}

.show_navigation_4 .owl-nav button:focus {
	outline: 0;
	box-shadow: none
}

.single-event {
	display: flex;
	justify-content: space-between
}

.single-event .event-date {
	display: inline-block;
	padding: 5px 35px;
	background-color: #fff;
	text-align: center;
	margin-right: 30px;
	border: 4px solid #ddd;
	min-width: 160px;
	height: 160px
}

.single-event .event-date span {
	font-size: 65px;
	color: #029115;
	font-weight: 600
}

.single-event .event-date p {
	font-size: 24px;
	color: #56566a;
	font-weight: 500;
	margin-bottom: 0
}

.single-event h5 {
	font-size: 20px;
	font-weight: 600
}

.single-event h5 a {
	color: #002147;
	transition: all .4s linear 0s
}

.single-event h5 a:hover {
	color: #029115;
	text-decoration: none
}

.single-event .event-meta {
	display: flex
}

.single-event .event-meta .single-meta {
	padding-left: 40px
}

.single-event .event-meta .single-meta:first-child {
	padding-left: 0
}

@media(min-width:1199px) {
	.single-event .event-meta .single-meta p {
		font-size: 16px!important
	}
}

@media(min-width:992px) {
	.single-event .event-meta .single-meta p {
		font-size: 12px
	}
}

@media(max-width:575px) {
	.single-event .event-meta .single-meta p {
		font-size: 12px
	}
}

.single-event i {
	color: #029115
}

.event-content h2 {
	font-size: 24px;
	margin-bottom: 15px;
	font-family: poppins
}

.event-content h2 a {
	color: #002147;
	transition: all .4s linear 0s
}

.event-content h2 a:hover {
	text-decoration: none;
	color: #029115
}

.event-content-one h2 {
	font-size: 24px;
	margin-bottom: 15px;
	font-family: poppins;
	font-weight: 600
}

.event-content-one h2 a {
	color: #002147;
	transition: all .4s linear 0s
}

.event-content-one h2 a:hover {
	text-decoration: none;
	color: #029115
}

.single-event-2 {
	display: flex;
	justify-content: space-between
}

.single-event-2 .event-date {
	display: inline-block;
	padding: 5px 35px;
	background-color: #fff;
	text-align: center;
	margin-right: 30px;
	border: 3px solid #ddd;
	min-width: 160px
}

.single-event-2 .event-date span {
	font-size: 65px;
	color: #14bdee;
	font-weight: 600
}

.single-event-2 .event-date p {
	font-size: 24px;
	color: #56566a;
	margin-bottom: 0;
	font-weight: 600
}

.single-event-2 h5 {
	font-size: 20px;
	font-weight: 500
}

.single-event-2 h5 a {
	color: #002147;
	transition: all .4s linear 0s
}

.single-event-2 h5 a:hover {
	color: #14bdee;
	text-decoration: none
}

.single-event-2 .event-meta {
	display: flex
}

.single-event-2 .event-meta .single-meta {
	padding-left: 40px
}

@media(min-width:992px) {
	.single-event-2 .event-meta .single-meta {
		padding-left: 12px
	}
}

.single-event-2 .event-meta .single-meta:first-child {
	padding-left: 0
}

@media(max-width:575px) {
	.single-event-2 .event-meta .single-meta p {
		font-size: 12px
	}
}

@media(min-width:992px) {
	.single-event-2 .event-meta .single-meta p {
		font-size: 14px
	}
}

.single-event-2 i {
	color: #14bdee
}

.single-event-3 {
	display: flex;
	justify-content: space-between
}

.single-event-3 .event-date {
	display: inline-block;
	padding: 5px 35px;
	background-color: #fff;
	text-align: center;
	margin-right: 30px;
	border: 3px solid #ddd;
	min-width: 160px
}

.single-event-3 .event-date span {
	font-size: 65px;
	color: #91c73d;
	font-weight: 600
}

.single-event-3 .event-date p {
	font-size: 24px;
	color: #56566a;
	margin-bottom: 0
}

.single-event-3 h5 {
	font-size: 20px;
	font-weight: 500
}

.single-event-3 h5 a {
	color: #002147;
	transition: all .4s linear 0s
}

.single-event-3 h5 a:hover {
	color: #91c73d;
	text-decoration: none
}

.single-event-3 .event-meta {
	display: flex
}

.single-event-3 .event-meta .single-meta {
	padding-left: 40px
}

@media(min-width:992px) {
	.single-event-3 .event-meta .single-meta {
		padding-left: 12px
	}
}

.single-event-3 .event-meta .single-meta:first-child {
	padding-left: 0
}

@media(max-width:575px) {
	.single-event-3 .event-meta .single-meta p {
		font-size: 12px
	}
}

@media(min-width:992px) {
	.single-event-3 .event-meta .single-meta p {
		font-size: 14px
	}
}

.single-event-3 i {
	color: #91c73d
}

.event-content h2 {
	font-size: 24px;
	margin-bottom: 15px
}

.event-content h2 a {
	color: #002147;
	transition: all .4s linear 0s
}

.event-content h2 a:hover {
	text-decoration: none;
	color: #14bdee
}

.events-info {
	display: flex;
	position: absolute;
	bottom: 30px;
	justify-content: space-between;
	padding: 15px 20px;
	width: 85%;
	left: 7%;
	border-radius: 30px;
	font-size: 14px;
	color: #56566a
}

@media(max-width:575px) {
	.events-info {
		width: 90%;
		left: 5%
	}
}

.events-info i {
	color: #029115;
	font-size: 15px
}

.events-info p {
	margin-bottom: 0
}

@media(max-width:575px) {
	.events-info p {
		font-size: 12px
	}
}

.events-info-2 {
	display: flex;
	justify-content: center;
	position: absolute;
	bottom: 30px;
	padding: 15px 20px;
	width: 80%;
	left: 10%;
	border-radius: 30px;
	font-size: 14px;
	color: #56566a
}

@media(max-width:768px) {
	.events-info-2 {
		width: 96%;
		left: 2%
	}
}

@media(max-width:575px) {
	.events-info-2 {
		width: 96%;
		left: 2%
	}
}

.events-info-2 i {
	color: #029115;
	font-size: 15px
}

.events-info-2 p {
	margin-bottom: 0
}

.events-info-2 .single-event-data {
	padding: 0 5px
}

.events-info-2 .single-event-data a {
	color: #029115
}

.events-info-2 .single-event-data a:hover {
	text-decoration: none
}

.events-info-2 .single-event-data span {
	color: #029115;
	font-weight: 500
}

.single-blog .post-meta {
	position: absolute;
	bottom: 30px;
	padding: 15px 20px;
	width: 70%;
	left: 15%;
	border-radius: 30px;
	font-size: 14px;
	color: #56566a;
	text-align: center
}

@media(max-width:575px) {
	.single-blog .post-meta {
		width: 90%;
		left: 5%
	}
}

.single-blog .post-meta i {
	color: #029115;
	font-size: 15px
}

.single-blog .post-meta p {
	margin-bottom: 0
}

.single-blog .post-meta .single-event-data {
	display: inline-block
}

.single-blog .post-meta .single-event-data a {
	color: #029115
}

.single-blog .post-meta .single-event-data p {
	padding: 0 5px;
	font-size: 14px;
	color: #666
}

.event-image {
	position: relative;
	overflow: hidden
}

@media(min-width:768px) and (max-width:991px) {
	.event-image img {
		width: 100%
	}
}

.single-testimonial {
	position: relative;
	margin-top: 30px;
	padding-top: 2.3em!important
}

.single-testimonial:before {
	content: "\f10d";
	position: absolute;
	left: 30px;
	top: -30px;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	line-height: 50px;
	background-color: #029115;
	color: #fff;
	font-family: "font awesome 5 free";
	font-weight: 900;
	text-align: center
}

.single-testimonial p {
	line-height: 28px
}

.testimonial-referance p {
	margin-bottom: 0
}

.single-testimonial-2 {
	position: relative;
	margin-top: 80px
}

.single-testimonial-2:before {
	content: "\f10d";
	position: absolute;
	left: 125px;
	top: -85px;
	z-index: 9;
	width: 45px;
	height: 45px;
	border-radius: 50%;
	line-height: 40px;
	background-color: #14bdee;
	color: #fff;
	font-family: "font awesome 5 free";
	font-weight: 900;
	font-size: 14px;
	text-align: center;
	border: 3px solid #fff
}

.single-testimonial-2 .testimonial-pic {
	position: absolute;
	top: -55px;
	left: 40px
}

.single-testimonial-2 .testimonial-pic img {
	width: 110px;
	height: 110px
}

.single-testimonial-2 .testimonial-text p {
	font-style: italic;
	font-size: 18px
}

.single-testimonial-2 .testimonial-referance strong {
	color: #002147;
	font-size: 16px
}

.single-testimonial-2 .testimonial-referance p {
	font-size: 16px;
	margin-bottom: 0
}

.single-testimonial-2 p {
	line-height: 28px
}

.single-testimonial-3 {
	position: relative;
	margin-top: 60px;
	padding-top: 30px
}

.single-testimonial-3:before {
	content: "\f10d";
	position: absolute;
	left: 50%;
	top: -40px;
	transform: translateX(-50%);
	width: 50px;
	height: 50px;
	border-radius: 50%;
	line-height: 50px;
	background-color: #029115;
	color: #fff;
	font-family: "font awesome 5 free";
	font-weight: 900;
	text-align: center
}

.single-testimonial-3 p {
	font-style: italic;
	font-size: 16px
}

.single-testimonial-3 .testimonial-referance strong {
	display: block
}

.single-testimonial-3 p {
	line-height: 28px
}

.single-testimonial-4 {
	position: relative;
	margin-top: 60px;
	padding-top: 30px
}

.single-testimonial-4:before {
	content: "\f10d";
	position: absolute;
	left: 50%;
	top: -40px;
	transform: translateX(-50%);
	width: 50px;
	height: 50px;
	border-radius: 50%;
	line-height: 50px;
	background-color: #14bdee;
	color: #fff;
	font-family: "font awesome 5 free";
	font-weight: 900;
	text-align: center
}

.single-testimonial-4 p {
	font-style: italic;
	font-size: 16px
}

.single-testimonial-4 .testimonial-referance strong {
	display: block
}

.single-testimonial-4 p {
	line-height: 28px
}

.single-testimonial-6 {
	position: relative;
	text-align: center;
	margin-bottom: 45px
}

.single-testimonial-6>p {
	display: inline-block;
	font-style: italic;
	font-size: 16px
}

.single-testimonial-6>p:after {
	content: "\f10e";
	font-family: "font awesome 5 free";
	font-weight: 900;
	position: absolute;
	padding-left: 10px;
	font-size: 22px;
	color: #1e2458
}

.single-testimonial-6 .testimonial-referance p {
	margin-bottom: 0
}

.single-testimonial-6 .quote-author-pic {
	position: absolute;
	left: 50%;
	bottom: -45px;
	background-repeat: no-repeat;
	background-size: cover;
	width: 90px;
	height: 90px;
	transform: translateX(-50%)
}

.single-testimonial-6 .quote-author-pic img {
	width: 100%;
	height: 100%;
	border-radius: 50%
}

.single-testimonial-6 p {
	line-height: 28px
}

.testimonials-wrapper .owl-dots {
	text-align: center;
	margin-top: 60px;
	line-height: 10px
}

.testimonials-wrapper .owl-dots button:focus {
	outline: 0;
	box-shadow: none
}

.testimonials-wrapper .owl-dots .owl-dot.active span {
	background-color: #029115;
	height: 30px
}

.testimonials-wrapper .owl-dots .owl-dot span {
	width: 10px;
	height: 20px;
	margin: 5px 7px 0;
	background: #ffc6ac;
	display: inline-block;
	-webkit-backface-visibility: visible;
	transition: opacity .2s ease;
	border-radius: 30px;
	transition: all .5s linear .2s
}

.testimonials-wrapper-2 .owl-dots {
	text-align: center;
	margin-top: 60px
}

.testimonials-wrapper-2 .owl-dots button:focus {
	outline: 0;
	box-shadow: none
}

.testimonials-wrapper-2 .owl-dots .owl-dot.active {
	transition: all .4s linear .09s
}

.testimonials-wrapper-2 .owl-dots .owl-dot.active span {
	background-color: #14bdee;
	height: 30px
}

.testimonials-wrapper-2 .owl-dots .owl-dot span {
	width: 10px;
	height: 20px;
	margin: 5px 7px;
	background: #c8f3ff;
	display: inline-block;
	-webkit-backface-visibility: visible;
	transition: opacity .2s ease;
	border-radius: 30px;
	transition: all .4s linear 0s
}

.testimonials-wrapper-3 .owl-dots {
	text-align: center;
	margin-top: 30px;
	line-height: 10px
}

.testimonials-wrapper-3 .owl-dots button:focus {
	outline: 0;
	box-shadow: none
}

.testimonials-wrapper-3 .owl-dots .owl-dot.active span {
	background-color: #029115;
	height: 30px
}

.testimonials-wrapper-3 .owl-dots .owl-dot span {
	width: 10px;
	height: 20px;
	margin: 0 5px 0 7px;
	background: #002147;
	display: inline-block;
	-webkit-backface-visibility: visible;
	transition: opacity .2s ease;
	border-radius: 30px;
	transition: all .4s linear 0s
}

.testimonials-wrapper-4 .owl-dots {
	text-align: center;
	margin-top: 30px
}

.testimonials-wrapper-4 .owl-dots button:focus {
	outline: 0;
	box-shadow: none
}

.testimonials-wrapper-4 .owl-dots .owl-dot.active span {
	background-color: #14bdee;
	height: 30px
}

.testimonials-wrapper-4 .owl-dots .owl-dot span {
	width: 10px;
	height: 20px;
	margin: 5px 7px;
	background: #fff;
	display: inline-block;
	-webkit-backface-visibility: visible;
	transition: opacity .2s ease;
	border-radius: 30px;
	transition: all .4s linear 0s
}

.has_bordered {
	position: relative
}

.has_bordered h2 {
	display: inline-block;
	position: relative
}

.has_bordered h2:before {
	content: "";
	position: absolute;
	left: 50%;
	bottom: -5px;
	width: 50%;
	height: 1px;
	background-color: #14bdee;
	transform: translateX(-50%)
}

.blog-lists-wrapper .single-blog-wrapper {
	display: flex;
	justify-content: space-between;
	margin-bottom: 30px
}

.blog-lists-wrapper .single-blog-wrapper .blog-thumb img {
	width: 140px;
	height: 140px;
	object-fit: cover
}

.blog-lists-wrapper .single-blog-wrapper:last-child {
	margin-bottom: 0
}

.blog-lists-wrapper .blog-content-wrapper {
	position: relative
}

.blog-lists-wrapper .blog-content-wrapper h4 {
	font-size: 16px;
	font-weight: 600;
	font-family: poppins, sans-serif;
	line-height: 1.5rem
}

.blog-lists-wrapper .blog-content-wrapper h4 a {
	color: #002147;
	transition: all .4s linear 0s;
	font-weight: 600;
	font-family: poppins
}

.blog-lists-wrapper .blog-content-wrapper h4 a:hover {
	text-decoration: none;
	color: #029115
}

.blog-lists-wrapper .blog-content-wrapper p {
	margin-bottom: 5px
}

.blog-lists-wrapper-2 .single-blog-wrapper {
	display: flex;
	justify-content: space-between;
	margin-bottom: 30px
}

.blog-lists-wrapper-2 .single-blog-wrapper .blog-thumb img {
	width: 140px;
	height: 140px;
	object-fit: cover
}

.blog-lists-wrapper-2 .single-blog-wrapper:last-child {
	margin-bottom: 0
}

.blog-lists-wrapper-2 .blog-content-wrapper h4 {
	font-size: 16px;
	font-weight: 600;
	font-family: poppins, sans-serif;
	line-height: 1.5rem
}

.blog-lists-wrapper-2 .blog-content-wrapper h4 a {
	color: #002147;
	transition: all .4s linear 0s;
	font-family: poppins
}

.blog-lists-wrapper-2 .blog-content-wrapper h4 a:hover {
	text-decoration: none;
	color: #14bdee
}

.blog-lists-wrapper-2 .blog-content-wrapper p {
	margin-bottom: 5px
}

.blog-lists-wrapper-2 .post-meta {
	position: relative!important
}

.blog-lists-wrapper-3 .single-blog-wrapper {
	display: flex;
	justify-content: space-between;
	margin-bottom: 30px
}

.blog-lists-wrapper-3 .single-blog-wrapper .blog-thumb img {
	width: 140px;
	height: 140px;
	object-fit: cover
}

.blog-lists-wrapper-3 .single-blog-wrapper:last-child {
	margin-bottom: 0
}

.blog-lists-wrapper-3 .blog-content-wrapper h4 {
	font-size: 16px;
	font-weight: 500;
	font-family: poppins, sans-serif;
	line-height: 1.5rem
}

.blog-lists-wrapper-3 .blog-content-wrapper h4 a {
	color: #002147;
	transition: all .4s linear 0s;
	font-family: poppins
}

.blog-lists-wrapper-3 .blog-content-wrapper h4 a:hover {
	text-decoration: none;
	color: #91c73d
}

.blog-lists-wrapper-3 .blog-content-wrapper p {
	margin-bottom: 5px
}

.post-meta span {
	font-size: 14px;
	font-style: italic
}

.big-blog-area .blog-meta span {
	color: #029115;
	font-size: 14px;
	font-weight: 500
}

.big-blog-area .blog-meta span.seperator {
	font-size: 12px;
	color: #002147
}

.big-blog-area .blog-meta span a {
	color: #029115
}

.big-blog-area .blog-meta-2 span {
	color: #14bdee;
	font-size: 14px
}

.big-blog-area .blog-meta-2 span a {
	color: #14bdee
}

.big-blog-area .blog-text h3 {
	margin-bottom: 0;
	padding: 15px 0;
	text-transform: capitalize;
	font-weight: 600
}

.big-blog-area .blog-text h3 a {
	color: #002147;
	transition: all .4s linear 0s;
	margin-top: 0;
	text-transform: capitalize;
	font-size: 20px;
	line-height: 1.8rem;
	font-weight: 600;
	font-family: poppins
}

.big-blog-area .blog-text h3 a:hover {
	text-decoration: none;
	color: #029115
}

.big-blog-area .blog-text a {
	display: inline-block;
	color: #029115;
	font-weight: 500;
	font-size: 15px;
	text-transform: uppercase;
	margin-top: 15px;
	transition: all .4s linear 0s
}

.big-blog-area .blog-text a i {
	transition: all .4s linear 0s
}

.big-blog-area .blog-text a:hover {
	text-decoration: none
}

.big-blog-area .blog-text a:hover i {
	padding-left: 5px
}

.big-blog-area .blog-text-2 h3 {
	margin-bottom: 0;
	padding: 15px 0;
	text-transform: capitalize
}

.big-blog-area .blog-text-2 h3 a {
	color: #002147;
	transition: all .4s linear 0s;
	margin-top: 0;
	text-transform: capitalize;
	font-size: 20px;
	line-height: 1.8rem;
	font-family: poppins;
	font-weight: 600
}

.big-blog-area .blog-text-2 h3 a:hover {
	text-decoration: none;
	color: #14bdee
}

.big-blog-area .blog-text-2 a {
	display: inline-block;
	color: #14bdee;
	font-weight: 500;
	font-size: 15px;
	text-transform: uppercase;
	margin-top: 15px;
	transition: all .4s linear 0s
}

.big-blog-area .blog-text-2 a i {
	transition: all .4s linear 0s
}

.big-blog-area .blog-text-2 a:hover {
	text-decoration: none
}

.big-blog-area .blog-text-2 a:hover i {
	padding-left: 5px
}

@media(min-width:768px) and (max-width:991px) {
	.big-blog-area .blog-thumb img {
		width: 100%
	}
}

@media(max-width:768px) {
	.big-blog-area .blog-thumb img {
		width: 100%
	}
}

@media(max-width:575px) {
	.big-blog-area .blog-thumb img {
		width: 100%
	}
}

.single_blog .blog-content {
	transition: all .4s linear 0s
}

.single_blog .event-image img {
	transition: all .4s linear 0s
}

.single_blog:hover .blog-content {
	box-shadow: 0 .5rem 1rem rgba(34, 34, 34, .15)!important
}

.single_blog:hover .event-image img {
	transform: scale3d(1.1, 1.1, 1.1)
}

.subscribe_box {
	display: flex;
	position: relative;
	height: 100%;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: #fff;
	min-height: 400px
}

.subscribe_box .subscribe-text {
	margin-bottom: 80px
}

.subscribe_box .subscribe-text i {
	font-size: 100px;
	margin-bottom: 40px
}

.subscribe_box .subscribe-text h3 {
	font-size: 50px
}

.subscribe_box .subscribe-text p {
	margin-bottom: 0;
	font-size: 18px;
	text-transform: capitalize
}

.subscribe_box .subscribe-form {
	position: absolute;
	width: 100%;
	bottom: 0;
	left: 0
}

.subscribe_box .subscribe-form button {
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	width: 80px;
	border: 0;
	background-color: #14bdee;
	color: #fff
}

.subscribe_box .subscribe-form button i {
	font-size: 35px
}

.subscribe_box .subscribe-form button:focus {
	outline: 0
}

.subscribe_box .subscribe-form input {
	border-radius: 0;
	height: 80px;
	background-color: #002959;
	color: #fff;
	border: 0;
	padding-left: 25px
}

.subscribe_box .subscribe-form input:focus {
	outline: 0;
	box-shadow: none
}

.footer-bottom-wrapper {
	border-color: rgba(255, 255, 255, .3)!important
}

.footer-area {
	color: #fff
}

.footer-area .footer-logo {
	background: #fff;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    margin-bottom: 20px;
}

@media(max-width:575px) {
	.footer-area .footer-logo {
		padding-top: 30px;
		padding-bottom: 20px
	}
}

.widget-item {
	display: flex;
	margin-bottom: 30px
}

.widget-item .widget-image {
	margin-right: 20px
}

.widget-item .widget-image img {
	width: 70px;
	height: 70px;
	object-fit: cover
}

.widget-item .widget-content h5 a {
	color: #fff;
	font-size: 16px;
	font-weight: 400;
	transition: all .4s linear 0s
}

.widget-item .widget-content h5 a:hover {
	text-decoration: none;
	color: #029115
}

.widget-item:last-child {
	margin-bottom: 0
}

.widget-item .widget-content-2 h5 a {
	color: #fff;
	font-size: 16px;
	font-weight: 400;
	transition: all .4s linear 0s
}

.widget-item .widget-content-2 h5 a:hover {
	text-decoration: none;
	color: #14bdee
}

.widget-item .widget-content-3 h5 a {
	color: #fff;
	font-size: 16px;
	font-weight: 400;
	transition: all .4s linear 0s
}

.widget-item .widget-content-3 h5 a:hover {
	text-decoration: none;
	color: #91c73d
}

.widget-item:last-child {
	margin-bottom: 0
}

.widget-meta span {
	font-size: 14px;
	font-style: italic
}

.widget-list ul {
	margin: 0;
	padding: 0;
	list-style: none
}

.widget-list ul li:first-child a {
	padding-top: 0
}

.widget-list ul li a {
	display: inline-block;
	font-size: 16px;
	color: #fff;
	padding: 5px;
	text-transform: capitalize;
	transition: all .4s linear 0s
}

.widget-list ul li a:hover {
	text-decoration: none;
	color: #029115
}

.widget-list-2 ul {
	margin: 0;
	padding: 0;
	list-style: none
}

.widget-list-2 ul li:first-child a {
	padding-top: 0
}

.widget-list-2 ul li a {
	display: inline-block;
	font-size: 16px;
	color: #fff;
	padding: 5px;
	text-transform: capitalize;
	transition: all .4s linear 0s
}

.widget-list-2 ul li a:hover {
	text-decoration: none;
	color: #14bdee
}

.widget-list-3 ul {
	margin: 0;
	padding: 0;
	list-style: none
}

.widget-list-3 ul li:first-child a {
	padding-top: 0
}

.widget-list-3 ul li a {
	display: inline-block;
	font-size: 16px;
	color: #fff;
	padding: 5px;
	text-transform: capitalize;
	transition: all .4s linear 0s
}

.widget-list-3 ul li a:hover {
	text-decoration: none;
	color: #91c73d
}

.social-accounts ul {
	margin: 0;
	padding: 0;
	list-style: none
}

.social-accounts ul li {
	display: inline-block
}

.social-accounts ul li:last-child a {
	margin-right: 0
}

.social-accounts ul li a {
	display: inline-block;
	width: 40px;
	height: 40px;
	line-height: 40px;
	color: #fff;
	border: 1px solid #2b344e;
	border-radius: 50%;
	background-color: #2b344e;
	transition: all .4s linear 0s;
	text-align: center;
	margin: 0 5px
}

@media(max-width:575px) {
	.social-accounts ul li a {
		width: 35px;
		height: 35px;
		line-height: 35px;
		margin: 0 3px
	}
}

.social-accounts ul li a:hover {
	background-color: #029115;
	color: #fff;
	border-color: #029115
}

.social-accounts-2 ul {
	margin: 0;
	padding: 0;
	list-style: none
}

.social-accounts-2 ul li {
	display: inline-block
}

.social-accounts-2 ul li:last-child a {
	margin-right: 0
}

.social-accounts-2 ul li a {
	display: inline-block;
	width: 40px;
	height: 40px;
	line-height: 40px;
	color: #fff;
	border: 1px solid #2b344e;
	border-radius: 50%;
	background-color: #2b344e;
	transition: all .4s linear 0s;
	text-align: center;
	margin: 0 5px
}

.social-accounts-2 ul li a:hover {
	background-color: #14bdee;
	color: #fff;
	border-color: #14bdee
}

.social-accounts-3 ul {
	margin: 0;
	padding: 0;
	list-style: none
}

.social-accounts-3 ul li {
	display: inline-block
}

.social-accounts-3 ul li:last-child a {
	margin-right: 0
}

.social-accounts-3 ul li a {
	display: inline-block;
	width: 40px;
	height: 40px;
	line-height: 40px;
	color: #fff;
	border: 1px solid #2b344e;
	border-radius: 50%;
	background-color: #2b344e;
	transition: all .4s linear 0s;
	text-align: center;
	margin: 0 5px
}

.social-accounts-3 ul li a:hover {
	background-color: #91c73d;
	color: #fff;
	border-color: #91c73d
}

.footer-bottom {
	display: flex;
	justify-content: space-between;
	align-items: center
}

.footer-bottom p {
	margin-bottom: 0
}

.address-widget p {
	line-height: 2rem
}

.single_widget h3 {
	margin-bottom: 0;
	padding-bottom: 20px;
	font-weight: 500;
	font-size: 24px;
}

.address-widget span {
	font-size: 28px
}

.subscribe-wrapper {
	position: relative;
	border-top: 10px solid rgba(255, 198, 172, .3);
	transform: translateY(50%)
}

.subscribe-wrapper h2 {
	font-family: poppins, sans-serif
}

.subscribe-wrapper input {
	font-style: italic
}

@media(max-width:768px) {
	.subscribe-wrapper {
		transform: translateY(49%)
	}
}

@media(max-width:575px) {
	.subscribe-wrapper {
		transform: translateY(49%)
	}
}

.subscribe-wrapper .subscribe-form form {
	position: relative
}

.subscribe-wrapper h2 {
	font-size: 50px;
	color: #002147
}

@media(max-width:575px) {
	.subscribe-wrapper h2 {
		font-size: 30px
	}
}

.subscribe-wrapper input {
	border: 2px solid #029115;
	border-radius: 0;
	height: 60px;
	border-radius: 35px;
	position: relative;
	padding: 0 15px;
	font-size: 14px
}

.subscribe-wrapper input:focus {
	outline: 0;
	box-shadow: none;
	border-color: #029115
}

.subscribe-wrapper button[type=submit] {
	background-color: #029115;
	color: #fff;
	text-transform: uppercase;
	font-weight: 500;
	position: absolute;
	height: 100%;
	border-radius: 35px;
	right: 0;
	top: 0;
	border: 0;
	width: 165px;
	font-size: 16px
}

.subscribe-wrapper button[type=submit]:focus {
	outline: 0;
	box-shadow: none
}

@media(min-width:768px) and (max-width:991px) {
	.subscribe-wrapper button[type=submit] {
		border-radius: 20px;
		width: 110px;
		font-size: 14px
	}
}

.subscribe-wrapper:before {
	content: "";
	position: absolute;
	left: -10px;
	top: -10px;
	height: 53%;
	width: 10px;
	background-color: rgba(255, 198, 172, .3)
}

.subscribe-wrapper:after {
	content: "";
	position: absolute;
	right: -10px;
	top: -10px;
	height: 53%;
	width: 10px;
	background-color: rgba(255, 198, 172, .3)
}

.scroll-top {
	position: fixed;
	width: 50px;
	height: 50px;
	background-color: #222;
	bottom: 80px;
	right: 20px;
	z-index: 9999;
	cursor: pointer;
	transition: all .4s linear 0s;
	display: none;
	border-radius: 50%
}

.scroll-top .scroll-icon {
	color: #fff;
	font-size: 20px;
	line-height: 53px;
	text-align: center
}

.scroll-top:hover {
	background-color: #029115
}

.scroll-top.option-2 {
	background-color: #14bdee
}

.scroll-top.option-3 {
	background-color: #91c73d
}

.hero-section {
	display: flex;
	position: relative;
	align-items: center;
	justify-content: center;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	overflow: hidden
}

.hero-section h1 {
	font-weight: 500
}

.image-gallery .owl-item {
	position: relative;
	z-index: 1;
	margin-top: 25px
}

.image-gallery .owl-item .single-gallery img {
	height: 250px;
	object-fit: cover
}

.image-gallery .owl-item.active.center {
	position: relative;
	z-index: 999
}

@media(min-width:992px) {
	.image-gallery .owl-item.active.center {
		transform: scale(1.3)
	}
}

.image-gallery .owl-item.active.center .single-gallery img {
	object-position: center center
}

@media(min-width:992px) {
	.image-gallery .owl-item.active.center .single-gallery img {
		border: 2px solid #029115;
		margin: 12px 0
	}
}

.image-gallery .owl-dots {
	display: inline-block;
	position: absolute;
	text-align: center;
	margin-top: -50px;
	z-index: 9999;
	left: 44.5%;
	background-color: #029115;
	padding: 5px;
	border-radius: 50px
}

.image-gallery .owl-dots .owl-dot {
	width: 10px;
	height: 10px;
	background-color: rgba(34, 34, 34, .3);
	border-radius: 50%;
	margin: 5px
}

.image-gallery .owl-dots .owl-dot.active {
	background-color: #fff
}

.image-gallery .owl-dots .owl-dot:focus {
	outline: 0
}

.featured-area {
	margin-bottom: -20px
}

.featured-2-icon {
	margin-right: 30px
}

.featured-2-icon i {
	color: #029115;
	font-size: 25px;
	width: 60px;
	height: 60px;
	line-height: 55px;
	text-align: center;
	border-radius: 50%;
	border: 2px solid #029115
}

.single-featured-3:hover .featured-2-icon i {
	background: #029115;
	color: #fff;
	transition: all .4s linear 0s
}

.featured-2-text h5 {
	color: #002147;
	font-size: 20px;
	font-family: poppins, sans-serif;
	font-weight: 600
}

.half-column-content h2 {
	font-size: 50px
}

.half-column-video:before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(34, 34, 34, .3)
}

.half-column-video .play-button {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	color: #14bdee;
	background-color: rgba(255, 255, 255, .6);
	padding: 15px;
	border-radius: 50%;
	transition: all .4s linear 0s
}

.half-column-video .play-button i {
	font-size: 30px;
	background-color: #fff;
	padding: 35px;
	border-radius: 50%
}

.half-column-video .play-button:hover {
	background-color: #14bdee
}

@media(max-width:768px) {
	.half-column-video {
		padding: 200px 0
	}
}

@media(max-width:575px) {
	.half-column-video {
		padding: 200px 0
	}
}

.contact-form-wrapper h2 {
	font-size: 50px;
	margin-bottom: 30px
}

.contact-form-wrapper input {
	margin-bottom: 30px;
	border-radius: 0;
	background-color: transparent;
	border: 2px solid #032f62;
	border-radius: 40px;
	height: 50px;
	padding: 25px;
	color: #ddd
}

.contact-form-wrapper input:focus {
	background-color: transparent;
	color: #ddd
}

.contact-form-wrapper textarea {
	border-radius: 0;
	background-color: transparent;
	margin-bottom: 30px;
	border-color: #032f62;
	border-radius: 40px;
	padding: 25px;
	color: #ddd
}

.contact-form-wrapper textarea:focus {
	background-color: transparent;
	color: #ddd
}

.contact-form-wrapper ::-webkit-input-placeholder {
	color: #ddd
}

.contact-form-wrapper ::-moz-placeholder {
	color: #ddd
}

.contact-form-wrapper :-ms-input-placeholder {
	color: #ddd
}

.contact-form-wrapper :-moz-placeholder {
	color: #ddd
}

.equal-height {
	background-size: cover
}

.reg-form-area {
	transform: translateY(-25%);
	position: absolute;
	width: 90%
}

@media(min-width:768px) and (max-width:991px) {
	.reg-form-area {
		position: relative;
		margin-bottom: -25%
	}
}

@media(max-width:768px) {
	.reg-form-area {
		position: relative;
		margin-bottom: -35%
	}
}

@media(max-width:575px) {
	.reg-form-area {
		position: relative;
		margin-bottom: -30%
	}
}

.reg-form-area h2 {
	margin-bottom: 30px;
	font-weight: 600;
	font-family: poppins
}

.reg-form-area input {
	border-radius: 0;
	margin-bottom: 30px;
	border: 1px solid #2f345b;
	background-color: #0d1028;
	color: #fff;
	height: 55px
}

.reg-form-area input:focus {
	background-color: #0d1028
}

.reg-form-area ::-webkit-input-placeholder {
	color: #fff
}

.reg-form-area ::-moz-placeholder {
	color: #fff
}

.reg-form-area :-ms-input-placeholder {
	color: #fff
}

.reg-form-area :-moz-placeholder {
	color: #fff
}

.reg-form-area button[type=submit] {
	border: 0;
	height: 55px;
	background-color: #91c73d;
	color: #fff;
	font-size: 16px;
	text-transform: uppercase;
	font-weight: 500;
	letter-spacing: 2px
}

.contact-from-wrapper input {
	border-radius: 0;
	height: 50px;
	background-color: #f9f9ff;
	position: relative
}

.contact-from-wrapper input:focus {
	background-color: #f5ffe6;
	border-color: #91c73d;
	box-shadow: none
}

.contact-from-wrapper input:focus label {
	color: #91c73d
}

.contact-from-wrapper label {
	position: absolute;
	right: 40px;
	top: 25px;
	transform: translateY(-50%);
	z-index: 3;
	color: #8a8d91
}

.contact-from-wrapper textarea {
	background-color: #f9f9ff;
	border-radius: 0;
	position: relative
}

.contact-from-wrapper textarea:focus {
	background-color: #f5ffe6;
	border-color: #91c73d;
	box-shadow: none
}

.contact-from-wrapper button[type=submit] {
	padding: 15px 25px;
	background-color: #91c73d;
	color: #fff;
	text-transform: uppercase;
	font-size: 15px;
	font-weight: 500;
	cursor: pointer;
	border: 0;
	border-radius: 50px
}

.contact-from-wrapper button[type=submit]:focus {
	outline: 0
}

.contact-from-wrapper-2 {
	overflow: hidden
}

.contact-from-wrapper-2 input {
	border-radius: 0;
	height: 50px;
	background-color: #f9f9ff;
	position: relative
}

.contact-from-wrapper-2 input:focus {
	background-color: #ffc6ac;
	border-color: #029115;
	box-shadow: none;
	color: #029115;
	font-weight: 500
}

.contact-from-wrapper-2 textarea {
	background-color: #f9f9ff;
	border-radius: 0;
	position: relative
}

.contact-from-wrapper-2 textarea:focus {
	background-color: #ffc6ac;
	border-color: #029115;
	box-shadow: none;
	color: #029115;
	font-weight: 500
}

.contact-from-wrapper-2 button[type=submit] {
	padding: 15px 25px;
	background-color: #029115;
	color: #fff;
	text-transform: uppercase;
	font-size: 15px;
	font-weight: 500;
	cursor: pointer;
	border: 0
}

.contact-from-wrapper-2 button[type=submit]:focus {
	outline: 0
}

.portfolios-list-2 ul li {
	margin: 5px
}

.single-timeline h4 {
	color: #002147;
	font-size: 20px;
	font-family: poppins, sans-serif;
	font-weight: 600
}

.single-timeline span {
	color: #029115;
	font-size: 14px
}

.single-timeline .timeline-text {
	padding-top: 25px
}

.single-timeline p {
	margin-bottom: 0
}

.timeline-wrapper {
	position: relative
}

@media(min-width:992px) {
	.timeline-wrapper:after {
		content: "\f105";
		font-family: "font awesome 5 free";
		font-weight: 900;
		position: absolute;
		right: 0;
		color: #fff;
		background-color: #029115;
		border-radius: 50%;
		text-align: center;
		top: -40px;
		width: 30px;
		height: 30px;
		font-size: 22px;
		line-height: 30px
	}
	.timeline-wrapper:before {
		content: "";
		position: absolute;
		left: 0;
		background-color: #e8e8e8;
		top: -40px;
		width: 30px;
		height: 30px;
		font-size: 22px;
		line-height: 30px
	}
	.timeline-wrapper .single-timeline {
		position: relative
	}
	.timeline-wrapper .single-timeline:before {
		content: "\f192";
		font-family: "font awesome 5 free";
		font-weight: 900;
		position: absolute;
		top: -50px;
		left: 0;
		width: 50px;
		height: 50px;
		color: #fff;
		background-color: #029115;
		border-radius: 50%;
		font-size: 30px;
		line-height: 50px;
		text-align: center
	}
}

.page-section-title {
	padding-bottom: 60px
}

.page-section-title h2 {
	font-size: 50px;
	color: #002147;
	font-family: poppins, sans-serif;
	font-weight: 600
}

.half_columnn_image img {
	width: 100%
}

.half_column_content h3 {
	font-size: 35px;
	color: #002147;
	font-weight: 600;
	font-family: poppins, sans-serif
}

.half_column_content h6 {
	font-size: 20px;
	color: #666;
	font-weight: 600
}

.half_column_content p {
	line-height: 28px
}

.secondary-content {
	display: inline-block;
	width: 50%;
	float: left
}

.secondary-content h6 {
	font-size: 16px;
	color: #002147
}

.single-member-info p {
	margin-bottom: 0
}

.single-member-info .member-intro h3 a {
	font-size: 20px;
	color: #002147;
	transition: all .4s linear 0s
}

.single-member-info .member-intro h3 a:hover {
	text-decoration: none
}

.single-member-info .member-intro p {
	font-size: 14px;
	color: #666
}

.single-member-info .member-intro p strong {
	color: #002147
}

.single-member-info .member-intro {
	padding: 20px 0 30px
}

.member-status {
	display: flex;
	justify-content: space-between
}

.member-status p {
	font-size: 14px;
	color: #666
}

.member-status p strong {
	color: #002147;
	font-weight: 500
}

.member-image {
	overflow: hidden
}

.member-image img {
	transition: all .4s linear 0s
}

.single-member {
	transition: all .4s linear 0s
}

.single-member:hover {
	box-shadow: 0 .5rem 1rem rgba(34, 34, 34, .15)!important
}

.single-member:hover .member-intro h3 a {
	color: #029115
}

.single-member:hover .member-intro p {
	color: #029115
}

.single-member:hover .member-intro p strong {
	color: #029115
}

.single-member:hover .member-status p strong {
	color: #029115
}

.single-member:hover .member-image img {
	transform: scale3d(1.1, 1.1, 1.1)
}

.search-form select {
	border-radius: 0;
	margin-bottom: 20px;
	color: #666;
	height: 50px
}

.search-form select:focus {
	outline: 0;
	box-shadow: none
}

.search-form input {
	border-radius: 0;
	margin-bottom: 20px;
	color: #666;
	height: 50px
}

.search-form input:focus {
	outline: 0;
	box-shadow: none
}

.button-widget {
	background-size: cover;
	background-position: center center
}

.button-widget h3 {
	color: #002147;
	padding-bottom: 15px;
	font-size: 24px;
	line-height: 35px;
	font-weight: 500
}

.widget-video-img {
	position: relative;
	text-align: center
}

.widget-video-img:before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 33, 71, .5)
}

.widget-video-img a {
	display: inline-block;
	position: absolute;
	text-align: center;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 60px;
	color: #fff;
	background-color: #029115;
	border-radius: 50%;
	line-height: 55px;
	transition: all .4s linear 0s;
	padding: 10px
}

.widget-video-img:hover a {
	background-color: #fff;
	color: #029115
}

.widget-video-img img {
	width: 100%
}

.highlights-section ul {
	margin: 0;
	padding: 0;
	list-style: none;
	padding: 10px 0
}

.highlights-section ul li {
	display: inline-block;
	padding: 5px;
	font-size:16px;
}
.highlights-section ul li a{
	color: #fff;
}
.highlights-section ul h4 {
	line-height: 35px
}

.highlights-section .social-links ul li a {
	display: inline-block;
	width: 40px;
	height: 40px;
	line-height: 40px;
	color: #fff;
	border: 2px solid #fff;
	border-radius: 50%;
	background-color: rgba(255, 255, 255, .19);
	transition: all .4s linear 0s;
	text-align: center
}

.highlights-section .social-links ul li a:hover {
	background-color: #fff;
	border-color: #fff;
	color: #029115
}

.gallery-links {
	display: inline-block;
	padding-top: 15px;
	border-top: 2px solid #fff
}

.gallery-links ul {
	margin: 0;
	padding: 0
}

.gallery-links ul li {
	display: inline-block;
	margin: 5px
}

.gallery-links ul li a {
	display: inline-block;
	width: 50px;
	height: 50px;
	text-align: center;
	line-height: 50px;
	background-color: rgba(255, 82, 2, .3);
	border-radius: 50%;
	border: 2px solid #fff;
	color: #fff;
	transition: all .4s linear 0s
}

.gallery-links ul li a:hover {
	background-color: #fff;
	color: #029115;
	border-color: #fff
}

.gallery-content {
	text-align: center;
	position: absolute;
	left: 50%;
	top: 50%;
	width: 100%;
	transform: translate(-50%, -50%);
	opacity: 0;
	visibility: hidden;
	transition: all .4s linear 0s
}

.gallery-content h3 {
	font-size: 30px;
	color: #fff;
	margin-bottom: 20px;
	padding: 0 25px
}

.single-gallery {
	transition: all .4s linear 0s
}

.single-gallery .gallery-image {
	position: relative
}

.single-gallery .gallery-image:before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 82, 2, .8);
	opacity: 0;
	visibility: hidden;
	transition: all .4s linear 0s
}

.single-gallery .gallery-image:hover:before {
	opacity: 1;
	visibility: visible
}

.single-gallery .gallery-image img {
	width: 100%
}

.single-gallery:hover .gallery-content {
	opacity: 1;
	visibility: visible
}

.post-text h2 a {
	font-size: 30px;
	color: #002147;
	transition: all .4s linear 0s
}

.post-text h2 a:hover {
	text-decoration: none;
	color: #029115
}

.search-widget form {
	position: relative
}

.search-widget form input {
	border-radius: 0;
	height: 60px;
	background-color: #f7f7f7
}

.search-widget form input.home2-opt:focus {
	border-color: #14bdee
}

.search-widget form input:focus {
	outline: 0;
	box-shadow: none;
	border-color: #029115
}

.search-widget form button[type=submit] {
	position: absolute;
	top: 0;
	right: 0;
	background-color: #029115;
	color: #fff;
	width: 80px;
	height: 100%;
	font-size: 25px;
	color: #fff;
	border: 0
}

.search-widget form button[type=submit].home2-opt {
	background-color: #14bdee
}

.latest-widget {
	padding: 25px
}

.latest-widget .single-latest {
	display: flex;
	padding: 20px 0;
	border-bottom: 1px solid #ddd
}

.latest-widget .single-latest .latest-image {
	padding-right: 10px
}

.latest-widget .single-latest .latest-image a {
	display: inline-block
}

.latest-widget .single-latest .latest-image img {
	width: 120px;
	height: 80px;
	object-fit: cover
}

.latest-widget .single-latest:last-child {
	border-bottom: 0;
	padding-bottom: 0
}

.latest-widget .single-latest .latest-content a {
	font-size: 16px;
	color: #002147;
	transition: all .4s linear 0s
}

.latest-widget .single-latest .latest-content a:hover {
	text-decoration: none;
	color: #029115
}

.latest-widget .single-latest .latest-content .latest-meta span {
	color: #666;
	font-size: 14px
}

.category-list ul {
	padding: 0;
	margin: 0;
	list-style: none
}

.category-list ul li a {
	display: flex;
	justify-content: space-between;
	color: #666;
	padding: 10px 0;
	transition: all .4s linear 0s
}

.category-list ul li a:hover {
	text-decoration: none;
	color: #029115
}

.category-list ul li:last-child {
	padding-bottom: 0
}

.sidebar-wrapper .single-widget {
	margin-bottom: 45px
}

.sidebar-wrapper .single-widget:last-child {
	margin-bottom: 0
}

.sidebar-wrapper .single-widget h4 {
	font-size: 20px;
	color: #002147;
	text-align: center;
	font-weight: 600;
	font-family: poppins, sans-serif
}

.tag-clouds ul {
	margin: 0;
	padding: 0;
	list-style: none
}

.tag-clouds ul li {
	display: inline-block
}

.tag-clouds ul li a {
	display: inline-block;
	color: #666;
	text-transform: uppercase;
	font-size: 15px;
	padding: 10px 15px;
	background-color: #f5f5f5;
	margin: 5px 3px;
	transition: all .4s linear 0s
}

.tag-clouds ul li a:hover {
	text-decoration: none;
	color: #fff;
	background-color: #029115
}

.apply-wrapper h2 {
	font-size: 50px;
	color: #002147;
	text-align: center;
	padding-bottom: 35px;
	font-family: poppins, sans-serif;
	font-weight: 600
}

@media(max-width:575px) {
	.apply-wrapper h2 {
		font-size: 35px
	}
}

.apply-wrapper input {
	border-radius: 0;
	height: 60px
}

.apply-wrapper input:focus {
	outline: 0;
	box-shadow: none;
	border-color: #029115
}

.apply-wrapper select {
	border-radius: 0;
	height: 60px
}

.apply-wrapper select:focus {
	outline: 0;
	box-shadow: none;
	border-color: #029115
}

.apply-wrapper textarea {
	border-radius: 0
}

.apply-wrapper textarea:focus {
	outline: 0;
	box-shadow: none;
	border-color: #029115
}

.form-group label {
	font-family: poppins, sans-serif;
	font-weight: 600;
	color: #002147
}

.single-event-info {
	display: flex;
	justify-content: space-around;
	flex-direction: column;	
	transition: all .4s linear 0s;
	border: 2px solid #ddd
}

.single-event-info .single-event-date {
	width: 135px;
	margin-bottom: 20px;
}

@media(max-width:768px) {
	.single-event-info {
		display: inline-block;
		text-align: center
	}
	.single-event-info .single-event-date {
		margin: 0 auto 20px
	}
}

.single-event-info:hover {
	box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);
	border-color: #029115!important
}

.single-event-info:hover .single-event-date {
	background-color: #029115;
	color: #fff
}

.single-event-info:hover .single-event-date span {
	color: #fff
}

.single-event-info:hover .single-event-date p {
	color: #fff
}

.single-event-content h3{
	font-size: 24px;
	color: #002147;
	transition: all .4s linear 0s;
	font-weight: 500
}

@media(min-width:768px) and (max-width:991px) {
	.single-event-content h3 a {
		font-size: 20px
	}
}

.single-event-content h3 a:hover {
	text-decoration: none;
	color: #029115
}

.single-event-content p {
	color: #666;
	margin-bottom: 15px
}

.single-event-content p i {
	padding-right: 5px
}

/*@media(min-width:992px) {
	.single-event-content {
		padding: 0 25px
	}
}*/

@media(min-width:768px) and (max-width:991px) {
	.single-event-content {
		padding-left: 20px
	}
}

.single-event-date {
	text-align: center;
	border: 2px solid #029115;
	transition: all .4s linear 0s
}

.single-event-date p {
	margin-bottom: 0;
	font-size: 20px;
	color: #029115
}

.single-event-date span {
	font-size: 50px;
	color: #029115;
	font-weight: 600
}

.single-blog .blog-meta {
	display: flex;
	justify-content: space-between;
	padding: 15px 0
}

.single-blog .blog-meta p {
	margin-bottom: 0;
	color: #666;
	font-size: 14px
}

.single-blog .blog-meta i {
	color: #029115
}

.single-blog .blog-text h4 a {
	color: #002147;
	font-size: 20px;
	transition: all .4s linear 0s
}

.single-blog .blog-text h4 a:hover {
	text-decoration: none;
	color: #029115
}

.blog-image a img {
	width: 100%
}

.search-form select {
	background-color: #f5f5f5
}

.search-form select:focus {
	border-color: #029115
}

.search-form input {
	background-color: #f5f5f5
}

.search-form input:focus {
	border-color: #029115
}

.results-area h2 {
	font-size: 50px;
	color: #002147
}

.results-area .table {
	margin-bottom: 0
}

.single-item {
	padding: 2rem 1.5rem;
	border: 2px solid #ddd;
	text-align: center;
	transition: all .4s linear 0s;
	min-height: 540px;
}

.single-item h4{
	color: #002147;
	padding: 15px 0;
	transition: all .4s linear 0s
}

.single-item h4 a:hover {
	text-decoration: none;
	color: #029115
}

.single-item .item-icon {
	padding-bottom: 10px
}

.single-item:hover {
	box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);
	border-color: #029115
}

.details-meta {
	display: flex;
	padding-top: .8em
}

.details-meta p {
	margin-bottom: 0
}

.details-meta .single-meta {
	padding-right: 50px
}

.details-meta .single-meta:last-child {
	padding-right: 0
}

.details-meta .single-meta i {
	color: #029115;
	padding-right: 5px
}

.details-meta .single-meta p {
	font-size: 14px;
	color: #666
}

@media(max-width:575px) {
	.details-meta .single-meta p {
		font-size: 12px
	}
}

.details-title h2 {
	font-size: 30px;
	color: #002147
}

.tags-and-share {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 30px 0;
	border-top: 2px solid #dee2e6;
	border-bottom: 2px solid #dee2e6;
	margin: 60px 0
}

.comments-count h2 {
	font-size: 20px;
	color: #002147;
	font-family: poppins, sans-serif;
	font-weight: 600
}

.author-thumb {
	width: 260px;
	padding-right: 20px
}

.author-thumb a {
	display: inline-block
}

.author-thumb img {
	width: 100%;
	height: 100%;
	border-radius: 50%
}

.authors-info {
	display: flex
}

.single-comment {
	position: relative
}

.single-comment .reply-btn {
	position: absolute;
	top: 0;
	right: 0;
	color: #029115;
	font-size: 16px;
	font-weight: 500
}

.single-comment .reply-btn:hover {
	text-decoration: none
}

.comments-count {
	margin-bottom: 30px
}

.author-data a {
	color: #002147;
	font-size: 16px;
	font-weight: 600;
	transition: all .4s linear 0s;
	font-family: poppins, sans-serif
}

.author-data a:hover {
	text-decoration: none;
	color: #029115
}

.author-data p {
	margin-bottom: 5px;
	margin-bottom: 8px;
	margin-top: 8px;
	font-family: poppins, sans-serif
}

.comment-form-wrapper h2 {
	font-size: 20px;
	color: #002147;
	margin-bottom: 35px
}

.comment-form-wrapper input {
	margin-bottom: 30px;
	border-radius: 0;
	background-color: #f5f5f5;
	height: 50px
}

.comment-form-wrapper input:focus {
	outline: 0;
	box-shadow: none;
	border-color: #029115
}

.comment-form-wrapper textarea {
	border-radius: 0;
	background-color: #f5f5f5;
	outline: 0;
	box-shadow: none;
	margin-bottom: 30px
}

.comment-form-wrapper textarea:focus {
	outline: 0;
	box-shadow: none;
	border-color: #029115
}

.single-blog-item {
	display: flex;
	border: 2px solid #dee2e6;
	transition: all .4s linear 0s
}

.single-blog-item:hover {
	border-color: #029115
}

.single-blog-item .single-blog-thumb {
	padding-right: 15px
}

.single-blog-item .single-blog-thumb a {
	display: inline-block
}

.single-blog-item .single-blog-thumb img {
	width: 140px;
	height: 75px;
	object-fit: cover
}

.single-blog-item .single-blog-content h4 a {
	font-size: 16px;
	color: #002147;
	transition: all .4s linear 0s
}

.single-blog-item .single-blog-content h4 a:hover {
	color: #029115;
	text-decoration: none
}

.single-blog-item .single-blog-content span {
	color: #666;
	font-size: 14px
}

.latest-blogs-area .owl-nav {
	text-align: center;
	margin-top: 35px
}

.latest-blogs-area .owl-nav button {
	width: 45px;
	height: 45px;
	line-height: 45px;
	border-radius: 50%;
	background-color: #fff!important;
	color: #029115;
	margin: 5px;
	border: 2px solid #f5f5f5!important;
	transition: all .4s linear 0s
}

.latest-blogs-area .owl-nav button span {
	color: #029115;
	font-size: 25px
}

.latest-blogs-area .owl-nav button:hover {
	background-color: #029115!important;
	border-color: #029115!important
}

.latest-blogs-area .owl-nav button:hover span {
	color: #fff
}

.latest-blogs-area .owl-nav button:focus {
	outline: 0;
	box-shadow: none
}

#search_modal .modal-content {
	border-radius: 0
}

.details-text p {
	line-height: 30px
}

.timeline-area p {
	font-weight: 500
}

.kindergarten .button_two {
	font-family: poppins, sans-serif
}

.height-470 {
	height: 325px
}
.height-250 {
	height: 250px
}

.sticky-menu {
    position: sticky;
    top: 40px;
}
.side-menu ul{
	display: flex;
	flex-direction: column;
	padding: 0;
	margin: 0;
	list-style: none;
}
.side-menu ul li a{
	display: block;
    position: relative;
    padding: 6px 10px 6px 40px;
    color: #565656;
    font-size: 14px;
}
.side-menu ul li a i{
	position: absolute;
	left: 10px;
    top: 14px;
    color: #029115;
}
@media(max-width: 800px){
	.single-item{
		min-height: auto;
	}
	.single_widget{
		margin-bottom: 30px;
	}
	.main-menu ul li a {
	    padding: 0.5rem 1rem!important;
	}
}
@media(max-width:575px) {
	.why-ready .item{width: 100%;}
	.slider-area-2 .slider-content-2 p {
		display: none
	}
	.subscribe-wrapper button[type=submit] {
		width: 120px
	}
	.slider-area.height-700 {
		height: 300px
	}
	.slider-area-2.height-600 {
		height: 300px
	}
	.slider-area-3.height-600 {
		height: 300px
	}
	.blog-lists-wrapper .single-blog-wrapper {
		display: block
	}
	.blog-lists-wrapper .single-blog-wrapper .blog-thumb img {
		width: 100%;
		margin: 10px 0 15px
	}
	.single-event {
		display: block
	}
	.single-event .event-date {
		display: none
	}
	.single-event-3 {
		display: block
	}
	.single-event-3 .event-date,
	.single-event-2 .event-date {
		display: none
	}
	.blog-lists-wrapper-2 .single-blog-wrapper {
		display: block
	}
	.blog-lists-wrapper-2 .single-blog-wrapper .blog-thumb img {
		width: 100%;
		margin: 10px 0 15px
	}
	.slider-area-3 .slider-content h6 {
		font-size: 15px
	}
	.slider-content .display-3 {
		font-size: 1.8rem
	}
}




.sticky-menu2{ position: static !important;}
.nav-link:focus, .nav-link:hover{color:#fff !important;}
.colr-1{color: #029115 !important; font-weight: 500 !important;}
.bg-col{/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#393185+0,009846+100 */
	background: linear-gradient(to right,  #393185 0%,#009846 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	}