h2, h3, h5,h6, ul, li{
    text-align: left;
}

.box {
    transition: ease-in 3s; /* Define the transition */
  }
  
  .box.visible {
    opacity: 1; /* Make the box visible */
    transition: ease-in 3s;
  }

  .swiper {
    width: 100%;
    /* height: 100vh; */
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header {
    position: relative;
    z-index: 10; /* Ensure header is above other elements */
  }
  
  .swiper-container-2 {
    position: relative;
    z-index: 1; /* Ensure swiper is below the header */
  }
  
  


  .register{
    background: -webkit-linear-gradient(left, #6F55F6, #EF5869);
    margin-top: 3%;
    padding: 3%;
}
.register-left{
    text-align: center;
    color: #fff;
    margin-top: 4%;
}
.register-left input{
    border: none;
    border-radius: 1.5rem;
    padding: 2%;
    width: 60%;
    background: #f8f9fa;
    font-weight: bold;
    color: #383d41;
    margin-top: 30%;
    margin-bottom: 3%;
    cursor: pointer;
}
.register-right{
    background: #ffffff;
    /* opacity: 1.5; */
    /* border-top-left-radius: 10% 50%; */
    /* border-bottom-left-radius: 10% 50%; */
    border: 1px solid darkorchid;
    border-radius: 2%;
}
.register-left img{
    margin-top: 15%;
    margin-bottom: 5%;
    width: 25%;
    -webkit-animation: mover 2s infinite  alternate;
    animation: mover 1s infinite  alternate;
}
@-webkit-keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-20px); }
}
@keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-20px); }
}
.register-left p{
    font-weight: lighter;
    padding: 12%;
    margin-top: -9%;
}
.register .register-form{
    padding: 10%;
    margin-top: 10%;
}
.btnRegister{
    float: right;
    margin-top: 10%;
    border: none;
    border-radius: 1.5rem;
    padding: 2%;
    background: #0062cc;
    color: #fff;
    font-weight: 600;
    width: 50%;
    cursor: pointer;
}
.register .nav-tabs{
    margin-top: 3%;
    border: none;
    background: #0062cc;
    border-radius: 1.5rem;
    width: 28%;
    float: right;
}
.register .nav-tabs .nav-link{
    padding: 2%;
    height: 34px;
    font-weight: 600;
    color: #fff;
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
}
.register .nav-tabs .nav-link:hover{
    border: none;
}
.register .nav-tabs .nav-link.active{
    width: 100px;
    color: #0062cc;
    border: 2px solid #0062cc;
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
}
.register-heading{
    text-align: center;
    margin-top: 8%;
    margin-bottom: -15%;
    color: #495057;
   
}


input[type="checkbox"] {
  -webkit-appearance: none;
  position: relative;
  background-color: #8e9396;
  width: 120px;
  height: 40px;
  border-radius: 20px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transition: all 0.7s ease;
}

input[type="checkbox"]::before {
  content: "";
  position: absolute;
  left: 0;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 20px;
  transform: scale(0.8);
  transition: all 0.7s ease;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

input[type="checkbox"]:checked::before {
  background-color: #fff;
  left: 82px;
}

input[type="checkbox"]:checked {
  background-color: #0984e3;
}

.nav-tabs .nav-item .nav-link.active {
  background-color: #007bff;
  color: #fff;
}

.gradient-custom-2 {
  /* fallback for old browsers */
  background: #fccb90;
  
  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593);
  
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593);
  }
  
  .gradient-text {
    background:white;
    -webkit-background-clip: text;
    color: transparent;
  }


/* .dropdown-submenu {
  position: relative;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -1px;
}


.sub-dropdown-menu {
  display: none;
}

.sub-dropdown-menu.show {
  display: block;
} */

/* Make sure your dropdowns are hidden by default */
.dropdown-menu {
  display: none;
  position: absolute;
  background-color: #f8f9fa;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

/* Show the dropdown menu when hovering over the parent item */
.nav-item.dropdown:hover > .dropdown-menu {
  display: block;
}

/* Style for the sub-dropdown */
.dropdown-submenu {
  position: relative;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -1px;
}

/* Show the sub-dropdown menu when hovering over the parent item */
.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

/* Ensure links inside dropdowns are styled appropriately */
.dropdown-item {
  padding: 0.5rem 1rem;
  color: #212529;
  text-decoration: none;
  display: block;
}

.dropdown-item:hover,
.dropdown-item:focus {
  background-color: #f8f9fa;
  color: #16181b;
}


  
/* --------------------------------- */
/* 
/* Ensure all cards have the same height */
.service-wrapper .single-service-4 {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* Use flex-grow to push the button to the bottom */
.service-wrapper .single-service-4 .service-text {
  flex-grow: 1;
}

/* Button styling */
.read-more-btn {
  display: inline-block;
  width: 150px; /* Fixed width for the button */
  padding: 10px 0;
  text-align: center;
  background-color: #ffffff; /* Button color */
  color: #fff;
  border-radius: 5px;
  border: 1px solid black;
  transition: background-color 0.3s;
}

/* Button hover effect */
.read-more-btn:hover {
  background-color: #a1a3a4; /* Darken on hover */
}

/* Ensuring margin-top: auto pushes button to the bottom */
.mt-auto {
  margin-top: auto;
}

.navbar-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.nav-item {
  margin: 5px; /* Optional: Adds spacing between menu items */
}


/* Base styles for the navigation bar */
.navbar-nav .nav-link {
  font-size: 14px; /* Smaller font size */
  padding: 5px 10px; /* Adjust padding */
}

.navbar-nav .dropdown-menu {
  font-size: 12px; /* Smaller font size for submenus */
  padding: 5px 0; /* Reduce top and bottom padding */
  min-width: 150px; /* Reduce submenu width */
}

.navbar-nav .dropdown-menu li a {
  padding: 5px 10px; /* Adjust link padding */
}

.navbar-nav .dropdown-menu li {
  margin: 0; /* Remove margins for compact layout */
}

/* Additional hover effects for better visibility */
.navbar-nav .dropdown-menu li a:hover {
  background-color: #f8f9fa13; /* Light gray background on hover */
  color: #000; /* Dark text on hover */
}

/* Adjust the submenu arrow icon size */
.navbar-nav .nav-link.dropdown-toggle::after {
  font-size: 10px; /* Smaller arrow icon */
}
